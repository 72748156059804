import { BulkEditFormData } from '../data/types'

const stringifyPreviewEditData = (previewData?: BulkEditFormData) =>
  previewData &&
  JSON.stringify(
    {
      ...(previewData.lambdaAlgoliaSrcKey
        ? { lambdaAlgoliaSrcKey: previewData.lambdaAlgoliaSrcKey }
        : {}),
      ...(previewData.cognitoLambdaTriggerSrcKey
        ? { cognitoLambdaTriggerSrcKey: previewData.cognitoLambdaTriggerSrcKey }
        : {}),
      ...(previewData.cognitoConfig
        ? { cognitoConfig: JSON.parse(previewData.cognitoConfig) }
        : {}),
    },
    null,
    2,
  )

export { stringifyPreviewEditData }
