import { IoExtensionPuzzleOutline, IoStorefrontOutline } from 'react-icons/io5'
import { MdOutlineDashboard } from 'react-icons/md'

import { classNames } from '@/utils'

export const links = [
  {
    id: 1,
    name: 'Dashboard',
    route: '/',
    icon: (isActive: boolean) => (
      <MdOutlineDashboard
        className={classNames('text-2xl text-gray', { 'text-saturine': isActive })}
      />
    ),
  },
  {
    id: 2,
    name: 'Groups',
    route: '/groups',
    icon: (isActive: boolean) => (
      <IoExtensionPuzzleOutline
        className={classNames('text-2xl text-gray', { 'text-saturine': isActive })}
      />
    ),
  },
  {
    id: 3,
    name: 'Stores',
    route: '/stores',
    icon: (isActive: boolean) => (
      <IoStorefrontOutline
        className={classNames('text-2xl text-gray', { 'text-saturine': isActive })}
      />
    ),
  },
]
