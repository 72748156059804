import { FC } from 'react'
import { format, parseISO } from 'date-fns'

import { Group } from '@/models/group'
import { Badge, InfoElement } from '@/components'
import { getBadgeVariantByStatus } from '@/utils'

import { BackendResourcesForm } from './BackendResourcesForm'

interface Props {
  group?: Group
}

const GroupDetailsTabContent: FC<Props> = ({ group }) => {
  const badgeVariant = getBadgeVariantByStatus(group?.workflowStatus)

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-3">
        <Badge className="w-fit text-sm" title="Status" {...badgeVariant}>
          {group?.workflowStatus}
        </Badge>
        <div className="flex gap-2 text-gray-700 font-bold text-lg">
          <span title="Workflow ID">{group?.workflowId}</span>
          <span>-</span>
          <span>{`Last Update (${format(
            parseISO(group?.updated ?? new Date().toISOString()),
            'MMM do, yyyy - hh:mm:ss',
          )})`}</span>
        </div>
      </div>
      <div className="p-6 rounded-lg border border-gray-100 flex divide-x-2 divide-gray-100 overflow-x-auto">
        <InfoElement className="grow shrink-0 pr-8" label="Name" value={group?.name} />
        <InfoElement className="grow shrink-0 pr-8 pl-8" label="Id" value={group?.id} />
        <InfoElement
          className="grow shrink-0 pr-8 pl-8"
          label="Created At"
          value={format(
            parseISO(group?.created ?? new Date().toISOString()),
            'MM/dd/yyyy - hh:mm:ss',
          )}
        />
        <InfoElement className="grow shrink-0 pr-8 pl-8" label="Domain" value={group?.domain} />
      </div>
      <BackendResourcesForm group={group} />
    </div>
  )
}

export { GroupDetailsTabContent }
