import { useCallback } from 'react'
import { useQuery } from '@tanstack/react-query'

import { GroupService } from '@/services/group'
import { Group } from '@/models/group'

export const useGroup = (groupId?: string) => {
  const queryKey = ['group', groupId]

  const getGroup = useCallback(async () => {
    const response = await GroupService.getGroupDetail(groupId as string)
    return response.data as Group
  }, [groupId])

  const { data, isLoading, refetch } = useQuery({
    queryKey,
    queryFn: getGroup,
    enabled: !!groupId?.length,
    refetchInterval: 5000,
  })

  return { group: data, isLoading, refetch }
}
