import { ReactNode } from 'react'
import { Control, useController } from 'react-hook-form'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import { CgSpinner } from 'react-icons/cg'

import { classNames } from '@/utils'

interface SelectFieldProps<T> {
  name: string
  options: { label: string; value: T }[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register?: any
  isError?: boolean
  isLoading?: boolean
  isDisabled?: boolean
  placeholder?: string
  optionClassName?: string
  checkIconClassName?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>
  itemTemplate?(value: T): ReactNode
  valueTemplate?(value: T): ReactNode
}

const SelectField = <T,>({
  name,
  isError,
  isLoading,
  options,
  placeholder,
  isDisabled,
  control,
  itemTemplate,
  valueTemplate,
  checkIconClassName,
  optionClassName,
}: SelectFieldProps<T>) => {
  const { field } = useController({ name, control })

  return (
    <Listbox
      {...field}
      disabled={isDisabled || isLoading}
      onChange={(data) => {
        field.onChange({ target: { name, value: data } })
      }}
    >
      {({ value }) => {
        const selectedOption = options.find(
          ({ value: optionValue }) => JSON.stringify(optionValue) === JSON.stringify(value),
        )
        const valueDisplay = valueTemplate ? valueTemplate(value) : selectedOption?.label

        return (
          <div className="relative mt-2">
            <ListboxButton
              className={classNames(
                'relative h-10 w-full cursor-pointer text-left border-[1px] rounded-[6px] text-sm px-[20px] py-1 text-gray-400 border-gray-200 focus:border-gray-300 outline-none',
                { 'border-red': isError },
                { 'cursor-default opacity-75 bg-gray-50': isDisabled },
              )}
            >
              <div className="truncate text-gray-400 h-full flex items-center">
                {!value ? placeholder : valueDisplay}
              </div>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                {isLoading ? (
                  <CgSpinner className="animate-spin text-lg" />
                ) : (
                  <ChevronDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                )}
              </span>
            </ListboxButton>
            <ListboxOptions
              transition
              className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md text-base shadow-lg ring-1 ring-opacity-0 ring-transparent focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
            >
              {({ open }) =>
                open ? (
                  <div className="bg-white py-1 ring-black ring-opacity-5">
                    {options.map(({ label, value }, index) => (
                      <ListboxOption
                        key={index}
                        value={value}
                        className={classNames(
                          'group relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-green-100 data-[selected]:bg-green-50 hover:bg-green-100',
                          optionClassName,
                        )}
                      >
                        {itemTemplate ? (
                          itemTemplate(value)
                        ) : (
                          <span className="block truncate font-normal group-data-[selected]:font-semibold group-data-[selected]:text-green-800">
                            {label}
                          </span>
                        )}
                        <span
                          className={classNames(
                            'invisible absolute inset-y-0 right-0 flex items-center pr-4 text-green-100 group-data-[selected]:visible group-data-[selected]:text-green-800',
                            checkIconClassName,
                          )}
                        >
                          <CheckIcon aria-hidden="true" className="h-5 w-5" />
                        </span>
                      </ListboxOption>
                    ))}
                  </div>
                ) : (
                  <></>
                )
              }
            </ListboxOptions>
          </div>
        )
      }}
    </Listbox>
  )
}

export { SelectField }
