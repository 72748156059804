import { RecordInterface } from '@/services/interfaces/record'
import { sendRequest } from '@/services/dataSources/axiosConfig'
import { RequestProps } from '@/services/dataSources/types'
import { AsyncApiResponse } from '@/models/apiResponse'
import { Record } from '@/models/record'

class RecordInterfaceImplementation implements RecordInterface {
  async getLastChangesByLimit(limit: number): AsyncApiResponse<Record[]> {
    const request: RequestProps = {
      get: {
        path: `/history/last/${limit}`,
      },
    }
    return await sendRequest<Record[]>(request)
  }
}

export const RecordService = new RecordInterfaceImplementation()