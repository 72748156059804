import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { FC } from 'react'
import { CgSpinner } from 'react-icons/cg'

import { ButtonApp } from '../buttonApp'

interface Props {
  visible: boolean
  headerTitle?: string
  confirmText: string
  isLoading?: boolean
  onHide(): void
  onConfirm?(): void
}

const ConfirmDialog: FC<Props> = ({
  visible,
  headerTitle,
  confirmText,
  isLoading,
  onHide,
  onConfirm,
}) => {
  return (
    <Dialog open={visible} onClose={onHide} className="relative z-[2000]">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <DialogTitle as="h3" className="text-lg font-semibold text-gray-900">
                  {headerTitle ?? 'Confirmation'}
                </DialogTitle>
                <div className="mt-2">
                  <p className="text-base text-gray-500">{confirmText}</p>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-8 sm:flex sm:flex-row-reverse gap-4">
              <ButtonApp
                isPrimary
                width="md:min-w-[160px] w-fit"
                className="h-12 flex items-center gap-2"
                handleClick={onConfirm}
              >
                {isLoading && <CgSpinner className="animate-spin text-lg" />}
                Accept
              </ButtonApp>
              <ButtonApp
                handleClick={onHide}
                isPrimary={false}
                width="md:min-w-[160px] w-fit"
                className="h-12"
              >
                Cancel
              </ButtonApp>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  )
}

export { ConfirmDialog }
