import {labels} from "@/constants/labels";

export default function Columns(groupName: string) {
    return ([
        {
            field: 'name',
            headerName: labels.name,
        },
        {
            field: 'group',
            headerName: labels.group,
            cellRenderer: () => (
                <p>{groupName}</p>
            ),
        },
        {
            field: 'description',
            headerName: labels.description,
        },
        {field: 'artifactsBucketName', headerName: labels.artifactsBucketName},
        {field: 'apiLambdaSrcKey', headerName: labels.apiLambdaSrcKey},
        {field: 'apiLambdaVersion', headerName: labels.apiLambdaVersion},
    ])
}