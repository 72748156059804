import { GroupInterface } from '@/services/interfaces/group'
import { sendRequest } from '@/services/dataSources/axiosConfig'
import { RequestProps } from '@/services/dataSources/types'
import { AsyncApiResponse } from '@/models/apiResponse'
import { BulkEditRequest, CreateGroupRequest, Group, UpdateGroupRequest } from '@/models/group'
import { Record } from '@/models/record'
import { Account, CreateAccountRequest } from '@/models/account'

class GroupServiceImplementation implements GroupInterface {
  async getAllGroups(): AsyncApiResponse<Group[]> {
    const request: RequestProps = {
      get: {
        path: '/group',
      },
    }
    return await sendRequest<Group[]>(request)
  }

  async getGroupDetail(id: string): AsyncApiResponse<Group> {
    const request: RequestProps = {
      get: {
        path: `/group/${id}`,
      },
    }
    return await sendRequest<Group>(request)
  }

  async getGroupHistory(id: string): AsyncApiResponse<Record[]> {
    const request: RequestProps = {
      get: {
        path: `/group/${id}/history`,
      },
    }
    return await sendRequest<Record[]>(request)
  }

  async getGroupAccounts(id: string): AsyncApiResponse<Account[]> {
    const request: RequestProps = {
      get: {
        path: `/group/${id}/accounts`,
      },
    }
    return await sendRequest<Account[]>(request)
  }

  async getGroupAccount(groupId: string, accountId: string): AsyncApiResponse<Account> {
    const request: RequestProps = {
      get: {
        path: `/group/${groupId}/accounts/${accountId}`,
      },
    }
    return await sendRequest<Account>(request)
  }

  async createGroup(data: CreateGroupRequest): AsyncApiResponse<boolean> {
    const request: RequestProps = {
      post: {
        path: `/group`,
        body: data,
      },
    }
    return await sendRequest<boolean>(request)
  }

  async createGroupAccount(groupId: string, data: CreateAccountRequest): AsyncApiResponse<boolean> {
    const request: RequestProps = {
      post: {
        path: `/group/${groupId}/accounts`,
        body: data,
      },
    }
    return await sendRequest<boolean>(request)
  }

  async updateGroupAccount(groupId: string, data: CreateAccountRequest): AsyncApiResponse<boolean> {
    const request: RequestProps = {
      put: {
        path: `/group/${groupId}/accounts/${data.id}`,
        body: data,
      },
    }
    return await sendRequest<boolean>(request)
  }

  async updateGroupBulk(data: BulkEditRequest): AsyncApiResponse<boolean> {
    const request: RequestProps = {
      post: {
        path: `/group/bulk/groups`,
        body: data,
      },
    }
    return await sendRequest<boolean>(request)
  }

  async updateGroup(data: UpdateGroupRequest, id: string): AsyncApiResponse<boolean> {
    const request: RequestProps = {
      put: {
        path: `/group/${id}`,
        body: data,
      },
    }
    return await sendRequest<boolean>(request)
  }

  async getLastGroups(limit: number): AsyncApiResponse<Group[]> {
    const request: RequestProps = {
      get: {
        path: `/group/last/${limit}`,
      },
    }
    return await sendRequest<Group[]>(request)
  }
}

export const GroupService = new GroupServiceImplementation()
