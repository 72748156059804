import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { OptionType } from '@/models/optionSelect'

import { FormData, UseCreateOrEditStoreModalProps } from '../data/types'
import { schema } from '../data/schema'

export const useCreateOrEditStoreModal = ({ store }: UseCreateOrEditStoreModalProps) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    clearErrors,
    setError,
    control,
  } = useForm<FormData>({
    defaultValues: {
      name: '',
      groupId: '',
      accountId: '',
      description: '',
    },
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    Object.entries(store ?? {}).forEach(([key, value]) => {
      setValue(key as keyof FormData, value as string)
    })
    setValue('name', store?.name ?? '')
    setValue('groupId', store?.groupId ?? '')
    setValue('accountId', store?.accountId ?? '')
    setValue('description', store?.description ?? '')
  }, [store])

  const handleChangeGroup = (option: OptionType) => {
    if (option?.id === '') {
      setError('groupId', {
        type: 'custom',
        message: 'Group is required',
      })
    } else {
      clearErrors('groupId')
    }
    setValue('groupId', (option as OptionType).id)
  }

  return {
    handleChangeGroup,
    register,
    handleSubmit,
    errors,
    watch,
    control,
  }
}
