export const dataSelectLimit = [
  { id: '5', label: '5' },
  { id: '10', label: '10' },
  { id: '20', label: '20' },
]

export const selectedLimitInitial = {
  id: '5',
  label: '5',
}
