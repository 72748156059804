import { useState } from 'react'
import { CgSpinner } from 'react-icons/cg'
import { RiArrowGoBackLine } from 'react-icons/ri'

import {
  ButtonApp,
  Collapse,
  FormApp,
  FormControl,
  ModalApp,
  SelectField,
  TextAreaInput,
} from '@/components'
import { classNames, getVersion } from '@/utils'

import { BulkEditFormData, BulkEditModalProps } from '../data/types'
import { useArtifacts, useBulkEditGroupModal, useBulkUpdateGroup } from '../hooks'
import { stringifyPreviewEditData } from '../utils'

export const BulkEditModal = ({ isOpenModal, handleCloseModal }: BulkEditModalProps) => {
  const { register, handleSubmit, errors, isDisabledButton, control } = useBulkEditGroupModal()
  const { artifacts, isLoading } = useArtifacts()
  const { update, isUpdating } = useBulkUpdateGroup({ onSuccess: handleCloseModal })
  const [previewData, setPreviewData] = useState<BulkEditFormData | undefined>(undefined)
  const previewDataString = stringifyPreviewEditData(previewData)

  const lambdaGraphqlSrcKeyOptions =
    artifacts?.['e-com-srv']?.map((artifact) => ({
      label: artifact,
      value: `e-com-srv/${artifact}`,
    })) ?? []
  const lambdaWebHookGroupSrcKeyOptions =
    artifacts?.['api-srv']?.map((artifact) => ({
      label: artifact,
      value: `api-srv/${artifact}`,
    })) ?? []
  const appWorkerOptions =
    artifacts?.['e-com-app-worker']?.map((artifact) => ({
      label: artifact,
      value: `e-com-app-worker/${artifact}`,
    })) ?? []
  const notificationWorkerOptions =
    artifacts?.['e-com-notification-worker']?.map((artifact) => ({
      label: artifact,
      value: `e-com-notification-worker/${artifact}`,
    })) ?? []
  const orderWorkerOptions =
    artifacts?.['e-com-order-worker']?.map((artifact) => ({
      label: artifact,
      value: `e-com-order-worker/${artifact}`,
    })) ?? []

  const renderItemTemplate = ({
    value,
    fieldType,
  }: {
    value: string
    fieldType: 'api' | 'worker'
  }) => (
    <span
      className={classNames('text-white rounded-full px-2 text-xs w-fit h-6 flex items-center', {
        'bg-[#2E59A3]': fieldType === 'api',
        'bg-[#288F58]': fieldType === 'worker',
      })}
    >
      {getVersion(value)}
    </span>
  )

  return (
    <ModalApp
      isOpenModal={isOpenModal}
      handleCloseModal={handleCloseModal}
      title="Bulk Edit"
      maxHeight="xs:max-h-[820px] md:max-h-[800px]"
      maxWidth={classNames('xs:max-w-[340px] md:max-w-[1094px]', {
        'md:max-w-[600px]': !!previewData,
      })}
    >
      {!!previewData && (
        <div className="flex flex-col">
          <div className="p-8 max-h-[60vh] overflow-auto">
            <pre className="border border-gray-200 rounded-[4px] p-4 text-gray-400 overflow-auto">
              {previewDataString}
            </pre>
          </div>
          <div className="flex xs:justify-center md:justify-end w-full py-6 px-8 border-t border-[#F0F2F7]">
            <div className="flex xs:flex-col-reverse md:flex-row md:justify-end gap-4 w-full">
              <ButtonApp
                handleClick={() => setPreviewData(undefined)}
                isPrimary={false}
                width="xs:w-full md:w-[160px]"
                className="h-12 flex gap-2 items-center"
              >
                <RiArrowGoBackLine className="text-lg" />
                <span>Go Back</span>
              </ButtonApp>
              <ButtonApp
                isPrimary
                width="xs:w-full md:w-[160px]"
                className="h-12 flex gap-2 items-center"
                handleClick={() => update(previewData)}
              >
                {isUpdating && <CgSpinner className="animate-spin text-lg" />}
                <span>Apply</span>
              </ButtonApp>
            </div>
          </div>
        </div>
      )}
      <FormApp
        handleCancel={handleCloseModal}
        handleSubmit={handleSubmit}
        onSubmit={(data: BulkEditFormData) => {
          setPreviewData(data)
        }}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onError={(errors: any) => {
          console.log(errors)
        }}
        textButton="Preview changes"
        isDisabledButton={isDisabledButton}
        className={classNames({ hidden: !!previewData })}
      >
        <div className="grid grid-cols-2 gap-6">
          <fieldset className="rounded-lg border border-[#F0F2F7] p-6 flex flex-col gap-6 self-start">
            <label>API</label>
            <div className="grid grid-cols-2 gap-6">
              <FormControl label="Webhook">
                <SelectField
                  name="lambdaWebHookGroupSrcKey"
                  register={register}
                  options={lambdaWebHookGroupSrcKeyOptions}
                  placeholder="Select version"
                  isLoading={isLoading}
                  control={control}
                  valueTemplate={(value) => renderItemTemplate({ value, fieldType: 'api' })}
                  itemTemplate={(value) => renderItemTemplate({ value, fieldType: 'api' })}
                  optionClassName="data-[focus]:bg-blue-50 data-[selected]:bg-blue-50 hover:bg-blue-50"
                  checkIconClassName="text-blue-100 group-data-[selected]:text-[#2E59A3]"
                />
              </FormControl>
              <FormControl label="GraphQL">
                <SelectField
                  name="lambdaGraphqlSrcKey"
                  register={register}
                  options={lambdaGraphqlSrcKeyOptions}
                  placeholder="Select version"
                  isLoading={isLoading}
                  control={control}
                  valueTemplate={(value) => renderItemTemplate({ value, fieldType: 'api' })}
                  itemTemplate={(value) => renderItemTemplate({ value, fieldType: 'api' })}
                  optionClassName="data-[focus]:bg-blue-50 data-[selected]:bg-blue-50 hover:bg-blue-50"
                  checkIconClassName="text-blue-100 group-data-[selected]:text-[#2E59A3]"
                />
              </FormControl>
            </div>
          </fieldset>
          <fieldset className="rounded-lg border border-[#F0F2F7] p-6 flex flex-col gap-6 self-start">
            <label>Workers</label>
            <div className="grid grid-cols-2 gap-6">
              <FormControl label="App">
                <SelectField
                  name="pubsubs.app.lambdaSrcKey"
                  register={register}
                  options={appWorkerOptions}
                  placeholder="Select version"
                  isLoading={isLoading}
                  control={control}
                  valueTemplate={(value) => renderItemTemplate({ value, fieldType: 'worker' })}
                  itemTemplate={(value) => renderItemTemplate({ value, fieldType: 'worker' })}
                  optionClassName="data-[focus]:bg-green-50 data-[selected]:bg-green-50 hover:bg-green-50"
                  checkIconClassName="text-green-100 group-data-[selected]:text-[#288F58]"
                />
              </FormControl>
              <FormControl label="Notification">
                <SelectField
                  name="pubsubs.notification.lambdaSrcKey"
                  register={register}
                  options={notificationWorkerOptions}
                  placeholder="Select version"
                  isLoading={isLoading}
                  control={control}
                  valueTemplate={(value) => renderItemTemplate({ value, fieldType: 'worker' })}
                  itemTemplate={(value) => renderItemTemplate({ value, fieldType: 'worker' })}
                  optionClassName="data-[focus]:bg-green-50 data-[selected]:bg-green-50 hover:bg-green-50"
                  checkIconClassName="text-green-100 group-data-[selected]:text-[#288F58]"
                />
              </FormControl>
              <FormControl label="Order">
                <SelectField
                  name="pubsubs.order.lambdaSrcKey"
                  register={register}
                  options={orderWorkerOptions}
                  placeholder="Select version"
                  isLoading={isLoading}
                  control={control}
                  valueTemplate={(value) => renderItemTemplate({ value, fieldType: 'worker' })}
                  itemTemplate={(value) => renderItemTemplate({ value, fieldType: 'worker' })}
                  optionClassName="data-[focus]:bg-green-50 data-[selected]:bg-green-50 hover:bg-green-50"
                  checkIconClassName="text-green-100 group-data-[selected]:text-[#288F58]"
                />
              </FormControl>
            </div>
          </fieldset>
          <div className="flex col-span-2 justify-end"></div>
          <Collapse className="w-full col-span-2">
            {({ open }) => (
              <>
                <Collapse.Header className="justify-end items-center text-gray-400 text-sm">
                  <div className="flex gap-1 cursor-pointer items-start">{`${open ? 'Hide' : 'Show'} details`}</div>
                </Collapse.Header>
                <Collapse.Panel className="grid grid-cols-2 gap-6">
                  {
                    <>
                      <fieldset className="rounded-lg border border-[#F0F2F7] p-6 flex flex-col gap-6 self-start">
                        <label>Pupsub Event Filter</label>
                        <FormControl
                          label="App"
                          messageError={errors.pubsubs?.app?.filterPolicyJson?.event_type?.message}
                        >
                          <TextAreaInput
                            rows={7}
                            name="pubsubs.app.filterPolicyJson.event_type"
                            placeholder="Enter app event types"
                            register={register}
                            isError={!!errors.pubsubs?.app?.filterPolicyJson?.event_type?.message}
                          />
                        </FormControl>
                        <FormControl
                          label="Notification"
                          messageError={
                            errors.pubsubs?.notification?.filterPolicyJson?.event_type?.message
                          }
                        >
                          <TextAreaInput
                            rows={7}
                            name="pubsubs.notification.filterPolicyJson.event_type"
                            placeholder="Enter notification event types"
                            register={register}
                            isError={
                              !!errors.pubsubs?.notification?.filterPolicyJson?.event_type?.message
                            }
                          />
                        </FormControl>
                        <FormControl
                          label="Order"
                          messageError={
                            errors.pubsubs?.order?.filterPolicyJson?.event_type?.message
                          }
                        >
                          <TextAreaInput
                            rows={7}
                            name="pubsubs.order.filterPolicyJson.event_type"
                            placeholder="Enter order event types"
                            register={register}
                            isError={!!errors.pubsubs?.order?.filterPolicyJson?.event_type?.message}
                          />
                        </FormControl>
                      </fieldset>
                      <fieldset className="rounded-lg border border-[#F0F2F7] p-6 flex flex-col gap-6 self-start">
                        <label>IDP</label>
                        <FormControl messageError={errors.cognitoConfig?.message}>
                          <TextAreaInput
                            rows={7}
                            name="cognitoConfig"
                            placeholder="Enter cognito config"
                            register={register}
                            isError={!!errors.cognitoConfig?.message}
                          />
                        </FormControl>
                      </fieldset>
                    </>
                  }
                </Collapse.Panel>
              </>
            )}
          </Collapse>
        </div>
      </FormApp>
    </ModalApp>
  )
}
