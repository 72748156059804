import { EyeIcon, PencilSquareIcon } from '@heroicons/react/24/solid'

type ActionsColumnProps = {
  handleEdit: () => void
  handleRedirectToInfoPage: () => void
  isShowEdit: boolean
}

export const ActionsColumn = ({
  handleEdit,
  handleRedirectToInfoPage,
  isShowEdit,
}: ActionsColumnProps) => (
  <div className="flex justify-start h-full">
    <div className="flex w-[60px] justify-end">
      <div className="flex gap-[20px] justify-end items-center">
        {isShowEdit && (
          <PencilSquareIcon
            className="text-black w-[24px] h-[24px] cursor-pointer transition ease-in-out delay-80 hover:scale-105"
            onClick={handleEdit}
          />
        )}
        <EyeIcon
          className="text-black w-[24px] h-[24px] cursor-pointer transition ease-in-out delay-80 hover:scale-105"
          onClick={handleRedirectToInfoPage}
        />
      </div>
    </div>
  </div>
)