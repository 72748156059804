import { RouteObject } from 'react-router-dom'

import { Groups, Home, ShowAccount, ShowGroup, ShowStore, Stores } from '@/pages'
import { Layout } from '@/components'

export enum ROUTES_PATHS {
  GROUPS = '/groups',
  STORES = '/stores',
}

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: ROUTES_PATHS.GROUPS,
        handle: { crumb: () => 'Groups' },
        children: [
          {
            index: true,
            element: <Groups />,
          },
          {
            path: ':groupId',
            handle: { crumb: () => 'Group Details' },
            children: [
              {
                index: true,
                element: <ShowGroup />,
              },
              {
                path: ':accountId',
                handle: { crumb: () => 'Account Details' },
                children: [
                  {
                    index: true,
                    element: <ShowAccount />,
                  },
                  {
                    path: ':storeId',
                    element: <ShowStore />,
                    handle: { crumb: () => 'Store Details' },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: ROUTES_PATHS.STORES,
        handle: { crumb: () => 'Stores' },
        children: [
          {
            index: true,
            element: <Stores />,
          },
          {
            path: ':storeId',
            element: <ShowStore />,
            handle: { crumb: () => 'Store Details' },
          },
        ],
      },
      { path: '*', element: <p>Not found</p> },
    ],
  },
]
