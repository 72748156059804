import { classNames } from '@/utils'
import { FC, ComponentProps, PropsWithChildren } from 'react'

type BadgeProps = ComponentProps<'div'> & Variants

const Badge: FC<PropsWithChildren<BadgeProps>> = ({
  isSuccess,
  isDanger,
  isInfo,
  isWarning,
  children,
  className,
  ...props
}) => {
  return (
    <div
      className={classNames(
        'py-0.5 px-2 bg-gray-100 text-gray-500 text-xs rounded-sm font-medium flex items-center select-none',
        { 'bg-green-50 text-green-800': isSuccess },
        { 'bg-blue-50 text-blue-200': isInfo },
        { 'bg-yellow-50 text-yellow-200': isWarning },
        { 'bg-red-50 text-red-200': isDanger },
        className,
      )}
      {...props}
    >
      {children}
    </div>
  )
}

type NeutralVariant = { isSuccess?: never; isInfo?: never; isWarning?: never; isDanger?: never }
type SuccessVariant = { isSuccess?: true; isInfo?: never; isWarning?: never; isDanger?: never }
type InfoVariant = { isSuccess?: never; isInfo?: true; isWarning?: never; isDanger?: never }
type WarningVariant = { isSuccess?: never; isInfo?: never; isWarning?: true; isDanger?: never }
type DangerVariant = { isSuccess?: never; isInfo?: never; isWarning?: never; isDanger?: true }

type Variants = NeutralVariant | SuccessVariant | InfoVariant | WarningVariant | DangerVariant

export { Badge }
