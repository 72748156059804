import { FC } from 'react'

import { Group } from '@/models/group'

interface Props {
  group?: Group
}

const GroupRawTabContent: FC<Props> = ({ group }) => {
  const outputs = JSON.parse(group?.outputs ?? '{}')

  return (
    <pre className="rounded-lg border border-gray-100 p-6 text-gray-500 text-sm">
      {JSON.stringify({ ...group, outputs }, null, 2)}
    </pre>
  )
}

export { GroupRawTabContent }
