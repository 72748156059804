/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode } from 'react'
import { UseFormHandleSubmit } from 'react-hook-form'
import { CgSpinner } from 'react-icons/cg'

import { ButtonApp } from '@/components'
import { classNames } from '@/utils'

type FormAppProps = {
  children: ReactNode
  handleCancel: () => void
  onSubmit: any
  onError?: any
  handleSubmit: UseFormHandleSubmit<any>
  isDisabledButton?: boolean
  isSubmitting?: boolean
  textButton?: string
  className?: string
  defaultButtonsHidden?: boolean
  outsideModal?: boolean
}

export const FormApp = ({
  children,
  handleSubmit,
  onSubmit,
  onError,
  handleCancel,
  textButton,
  isDisabledButton,
  className,
  isSubmitting,
  defaultButtonsHidden,
  outsideModal,
}: FormAppProps) => (
  <form
    onSubmit={handleSubmit(onSubmit, onError)}
    className={classNames('w-full', { 'flex flex-col min-h-0': !defaultButtonsHidden }, className)}
    name="form-app"
  >
    <div className={classNames({ 'p-8 max-h-[60vh] overflow-auto': !outsideModal })}>
      {children}
    </div>
    {!defaultButtonsHidden && (
      <div className="flex xs:justify-center md:justify-end w-full py-6 px-8 border-t border-[#F0F2F7]">
        <div className="flex xs:flex-col-reverse md:flex-row md:justify-end gap-4 w-full">
          <ButtonApp
            handleClick={handleCancel}
            isPrimary={false}
            width="md:min-w-[160px] w-fit"
            className="h-12"
          >
            Cancel
          </ButtonApp>
          <ButtonApp
            type="submit"
            isPrimary
            width="md:min-w-[160px] w-fit"
            className="h-12 flex items-center gap-2"
            isDisabled={isDisabledButton}
          >
            {isSubmitting && <CgSpinner className="animate-spin text-lg" />}
            {textButton ?? 'Save'}
          </ButtonApp>
        </div>
      </div>
    )}
  </form>
)
