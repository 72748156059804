import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { BulkEditFormData } from '../data/types'
import { bulkSchema } from '../data/schema'

export const useBulkEditStoreModal = () => {
  const {
    register,
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<BulkEditFormData>({
    defaultValues: {
      lambdaAlgoliaSrcKey: undefined,
      cognitoLambdaTriggerSrcKey: undefined,
      cognitoConfig: undefined,
    },
    mode: 'onChange',
    resolver: yupResolver(bulkSchema),
  })

  const isDisabledButton =
    !watch().lambdaAlgoliaSrcKey && !watch().cognitoLambdaTriggerSrcKey && !watch().cognitoConfig

  return {
    handleSubmit,
    errors,
    register,
    isDisabledButton,
    control,
  }
}
