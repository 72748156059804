import { classNames } from '@/utils'
import { ReactNode } from 'react'

type FormControlProps = {
  children: ReactNode
  messageError?: string
  label?: string
  isRequired?: boolean
  className?: string
}
export const FormControl = ({
  children,
  label,
  messageError,
  isRequired,
  className,
}: FormControlProps) => {
  return (
    <div className={classNames('flex flex-col w-full gap-[5px]', className)}>
      {label && (
        <p className="text-gray-400 text-xs uppercase">
          {label} {isRequired && <span className="text-red">*</span>}
        </p>
      )}
      <div className="flex flex-col gap-[5px]">
        {children}
        {messageError && <p className="text-red text-xs ml-[10px]">{messageError}</p>}
      </div>
    </div>
  )
}
