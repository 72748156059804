import { sendRequest } from '@/services/dataSources/axiosConfig'
import { RequestProps } from '@/services/dataSources/types'
import { AsyncApiResponse } from '@/models/apiResponse'
import { Artifacts } from '@/models/artifacts'

import { ArtifactInterface } from '../interfaces/artifact'

class ArtifactServiceImplementation implements ArtifactInterface {
  async getArtifacts(): AsyncApiResponse<Artifacts> {
    const request: RequestProps = {
      get: {
        path: '/artifacts',
      },
    }
    return await sendRequest<Artifacts>(request)
  }
}

export const ArtifactService = new ArtifactServiceImplementation()
