import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { CreateGroupRequest, Group, UpdateGroupRequest } from '@/models/group'
import { ApiRequestException, Status } from '@/models/apiResponse'
import { GroupService } from '@/services/group'
import { useGetAllGroups, useNotify } from '@/hooks'
import Columns from '@/pages/groups/data/columns'
import { FormData } from '@/pages/groups/data/types'

export const useGetGroups = () => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [group, setGroup] = useState<Group | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const { notifySuccess, notifyError } = useNotify()
  const { groups, isLoading: isLoadingGetAllGroups, refetch } = useGetAllGroups()

  const handleCreateGroup = async (data: CreateGroupRequest) => {
    setIsLoading(true)
    try {
      const response = await GroupService.createGroup(data)
      if (response.status === Status.success) {
        notifySuccess('Group created successfully')
        return true
      }
    } catch (error: unknown) {
      const errorResponse = error as ApiRequestException
      notifyError(errorResponse?.error?.content ?? 'There is an error')
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const handleEditGroup = async (data: UpdateGroupRequest) => {
    setIsLoading(true)
    try {
      const response = await GroupService.updateGroup(data, group?.id as string)
      if (response.status === Status.success) {
        notifySuccess('Group edited successfully')
        return true
      }
    } catch (error: unknown) {
      const errorResponse = error as ApiRequestException
      notifyError(errorResponse?.error?.content ?? 'There is an error')
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const handleSubmitGroup = async (data: FormData) => {
    let response
    if (group) {
      response = await handleEditGroup({ ...group, ...data })
    } else {
      response = await handleCreateGroup(data)
    }
    if (response) {
      await refetch()
      handleCloseModal()
    }
  }

  const handleSetGroup = (item: Group) => {
    setGroup(item)
    handleOpenModal()
  }

  const handleRedirectToInfoGroup = (id: string) => {
    navigate(`/groups/${id}`)
  }

  const columnsGroupDefs = Columns(handleSetGroup, handleRedirectToInfoGroup)

  const handleOpenModal = () => setIsOpenModal(true)

  const handleCloseModal = () => {
    setIsOpenModal(false)
    setGroup(null)
  }

  return {
    isOpenModal,
    group,
    handleCloseModal,
    handleOpenModal,
    columnsGroupDefs,
    groups,
    isLoading,
    handleSubmitGroup,
    isLoadingGetAllGroups,
    handleSetGroup,
  }
}
