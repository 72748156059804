import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useNotify } from '@/hooks'
import { GroupService } from '@/services/group'
import { Group } from '@/models/group'

import { BackendResourcesFormData } from '../data/types'

const useUpdateGroup = ({ onSuccess }: { onSuccess?(): void }) => {
  const { notifyError, notifySuccess } = useNotify()
  const queryClient = useQueryClient()

  const update = async (data: BackendResourcesFormData) => {
    const requestBody: Group = {
      ...data,
      pubsubs: {
        app: {
          ...data.pubsubs.app,
          filterPolicyJson: {
            ...data.pubsubs.app.filterPolicyJson,
            event_type:
              data.pubsubs.app.filterPolicyJson.event_type &&
              JSON.parse(data.pubsubs.app.filterPolicyJson.event_type),
          },
        },
        order: {
          ...data.pubsubs.order,
          filterPolicyJson: {
            ...data.pubsubs.order.filterPolicyJson,
            event_type:
              data.pubsubs.order.filterPolicyJson.event_type &&
              JSON.parse(data.pubsubs.order.filterPolicyJson.event_type),
          },
        },
        notification: {
          ...data.pubsubs.notification,
          filterPolicyJson: {
            ...data.pubsubs.notification.filterPolicyJson,
            event_type:
              data.pubsubs.notification.filterPolicyJson.event_type &&
              JSON.parse(data.pubsubs.notification.filterPolicyJson.event_type),
          },
        },
      },
      cognitoConfig: data?.cognitoConfig && JSON.parse(data?.cognitoConfig),
    }
    const result = await GroupService.updateGroup(requestBody, data.id)
    return result.data
  }

  const { mutate: updateGroup, isPending: isUpdating } = useMutation({
    mutationFn: update,
    onSuccess: async (_, { id }) => {
      await queryClient.refetchQueries({ queryKey: ['stores'], type: 'all' })
      await queryClient.refetchQueries({ queryKey: ['group', id], type: 'all' })
      notifySuccess('Group updated successfully')
      onSuccess?.()
    },
    onError: (error) => {
      notifyError(error.message ?? 'There was an error')
    },
  })

  return { updateGroup, isUpdating }
}

export { useUpdateGroup }
