import { useCallback } from 'react'
import { useQuery } from '@tanstack/react-query'

import { GroupService } from '@/services/group'
import { Account } from '@/models/account'

export const useGroupAccounts = (groupId?: string) => {
  const queryKey = ['group-accounts', groupId]

  const getAccounts = useCallback(async () => {
    const response = await GroupService.getGroupAccounts(groupId as string)
    return response.data as Account[]
  }, [groupId])

  const { data, isLoading, refetch } = useQuery({
    queryKey,
    queryFn: getAccounts,
    enabled: !!groupId?.length,
  })

  return { accounts: data ?? [], isLoading, refetch }
}
