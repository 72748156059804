import { toast } from 'react-toastify'

export const useNotify = () => {
  const notifySuccess = (message: string) => {
    toast.success(message)
  }
  const notifyError = (message: string) => {
    toast.error(message)
  }
  return { notifySuccess, notifyError }
}