import dayjs from 'dayjs'
import { IoEyeOutline } from 'react-icons/io5'

import { Record } from '@/models/record'
import { labels } from '@/constants/labels'
import { ButtonApp } from '@/components'

type ColumnProps = { data: Record }

export default function ColumnsRecords(selectRecord: (record: Record) => void) {
  return [
    {
      field: 'entityType',
      headerName: labels.typeOfFile,
    },
    { field: 'currentValue', headerName: labels.currentValue },
    { field: 'newValue', headerName: labels.newValue },
    {
      field: 'updated',
      headerName: labels.date,
      cellRenderer: (props: ColumnProps) => <p>{dayjs(props.data.updated).format('MM-DD-YYYY')}</p>,
    },
    {
      headerName: 'Compare changes',
      cellRenderer: ({ data: record }: ColumnProps) => (
        <div className="h-full flex items-center">
          <ButtonApp
            className="px-4 py-1.5 gap-2 h-8"
            handleClick={(e) => {
              e.stopPropagation()
              selectRecord(record)
            }}
          >
            <IoEyeOutline className="text-xl text-gray-400" />
            <span className="text-sm">Preview</span>
          </ButtonApp>
        </div>
      ),
    },
  ]
}
