import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useNotify } from '@/hooks'
import { UpdateStoreRequest } from '@/models/store'
import { StoreService } from '@/services/store'

const useUpdateStore = ({ onSuccess }: { onSuccess?(): void }) => {
  const { notifyError, notifySuccess } = useNotify()
  const queryClient = useQueryClient()

  const update = async (data: UpdateStoreRequest) => {
    const result = await StoreService.updateStore(data)
    return result.data
  }

  const { mutate: updateStore, isPending: isUpdating } = useMutation({
    mutationFn: update,
    onSuccess: async (_, { accountId, id }) => {
      await queryClient.refetchQueries({ queryKey: ['stores', accountId], type: 'all' })
      await queryClient.refetchQueries({ queryKey: ['store', id], type: 'all' })
      notifySuccess('Store updated successfully')
      onSuccess?.()
    },
    onError: (error) => {
      notifyError(error.message ?? 'There was an error')
    },
  })

  return { updateStore, isUpdating }
}

export { useUpdateStore }
