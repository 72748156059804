import { AgGridReact, AgGridReactProps } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { useCallback, useState } from 'react'

const DEFAULT_PAGINATION = 10

interface AgGridAppProps extends AgGridReactProps {
  rowData: any
  columnDefs: any
  isShowPagination?: boolean
  isShowFilter?: boolean
}

export const AgGridApp = ({
  rowData,
  columnDefs,
  isShowPagination = true,
  isShowFilter = true,
  ...props
}: AgGridAppProps) => {
  const [paginationLength, setPaginationLength] = useState(DEFAULT_PAGINATION)

  const getTableHeight = useCallback(() => {
    const itemsLength = Math.min(paginationLength, rowData.length)
    return 100 + itemsLength * 42
  }, [rowData, paginationLength])

  return (
    <div className="ag-theme-quartz" style={{ height: getTableHeight() }}>
      <AgGridReact
        className="xs:hidden md:block"
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={{
          filter: 'agTextColumnFilter',
          floatingFilter: isShowFilter,
          flex: 1,
        }}
        enableCellTextSelection
        pagination={isShowPagination}
        paginationPageSize={10}
        paginationPageSizeSelector={[10, 20, 30, 40, 50, 100]}
        rowClass="text-gray-600 text-sm h-auto"
        onPaginationChanged={({ api }) => setPaginationLength(api.paginationGetPageSize())}
        {...props}
      />
      <AgGridReact
        className="xs:block md:hidden"
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={{
          filter: 'agTextColumnFilter',
          floatingFilter: isShowFilter,
        }}
        pagination={isShowPagination}
        paginationPageSize={10}
        paginationPageSizeSelector={[10, 20, 30, 40, 50, 100]}
        rowClass="text-gray-600 text-sm h-auto"
        {...props}
      />
    </div>
  )
}
