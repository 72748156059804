import { FC } from 'react'
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid'
import { EyeIcon, PencilIcon } from '@heroicons/react/24/outline'
import { format, parseISO } from 'date-fns'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { useNavigate, useParams } from 'react-router-dom'

import { classNames, getBadgeVariantByStatus, getVersion } from '@/utils'
import { Badge, ButtonApp, ClipboardItem } from '@/components'
import { Store } from '@/models/store'
import { Param } from '@/models/params'

interface StoreCardProps {
  store: Store
  onEdit(_: Store): void
}

const StoreCard: FC<StoreCardProps> = ({ store, onEdit }) => {
  const navigate = useNavigate()
  const { name, description, workflowStatus, updated, id, workflowId } = store
  const badgeVariant = getBadgeVariantByStatus(workflowStatus)
  const { groupId, accountId } = useParams<Param>()

  const clipboardItems = [
    { label: 'Store ID', value: id },
    { label: 'Workflow ID', value: workflowId },
    {
      label: 'Cognito',
      value: getVersion(store.cognitoLambdaTriggerSrcKey) ?? store.cognitoLambdaTriggerSrcKey,
      version: getVersion(store.cognitoLambdaTriggerSrcKey) ?? store.cognitoLambdaTriggerSrcKey,
    },
    {
      label: 'Algolia',
      value: getVersion(store.lambdaAlgoliaSrcKey) ?? store.cognitoLambdaTriggerSrcKey,
      version: getVersion(store.lambdaAlgoliaSrcKey) ?? store.cognitoLambdaTriggerSrcKey,
    },
  ].filter(({ value }) => !!value)

  const menuItems = [
    {
      label: 'View Details',
      icon: <EyeIcon className="w-4" />,
      primary: true,
      command: ({ id }: Store) => {
        navigate(`/groups/${groupId}/${accountId}/${id}`)
      },
    },
    { label: 'Edit', icon: <PencilIcon className="w-4" />, primary: false, command: onEdit },
  ]

  return (
    <div
      className={classNames(
        'bg-white p-3 border border-gray-100 border-l-4 border-l-gray-200 flex flex-col gap-3 rounded-[4px] cursor-pointer select-none',
        { 'border-l-green-200': workflowStatus === 'SUCCEEDED' },
        { 'border-l-yellow-200': workflowStatus === 'REQUESTED' },
        { 'border-l-blue-200': workflowStatus === 'IN_PROGRESS' },
        { 'border-l-red-200': workflowStatus === 'FAILED' },
      )}
      role="button"
      onDoubleClick={() => {
        navigate(`/groups/${groupId}/${accountId}/${id}`)
      }}
    >
      <div className="flex justify-between">
        <span className="font-medium text-gray-700 truncate max-w-[60%]" title={name}>
          {name}
        </span>
        <div className="flex gap-2">
          <Badge {...badgeVariant}>{workflowStatus}</Badge>
          <Menu>
            <MenuButton>
              <EllipsisVerticalIcon width={22} className="text-gray-600" />
            </MenuButton>
            <MenuItems anchor="left start" className="bg-white p-2 shadow-xl flex flex-col gap-2">
              {menuItems.map(({ icon, label, primary, command }, index) => (
                <MenuItem key={index}>
                  <ButtonApp
                    className="px-4 py-1.5 gap-2"
                    isPrimary={primary}
                    handleClick={(e) => {
                      e.stopPropagation()
                      command(store)
                    }}
                  >
                    {icon}
                    <span className="text-sm">{label}</span>
                  </ButtonApp>
                </MenuItem>
              ))}
            </MenuItems>
          </Menu>
        </div>
      </div>
      <div className="flex gap-2 flex-wrap">
        {clipboardItems.map(({ label, value, version }, index) => (
          <ClipboardItem key={index} headless value={value}>
            {({ onClick, clipboardIcon }) => (
              <button
                className={classNames(
                  'py-1 px-3 border rounded-2xl text-xs flex items-center gap-2 text-gray-400 cursor-pointer',
                  { 'border-[#f5a66c]': !!version },
                )}
                onClick={onClick}
              >
                <span>{label}</span>
                {version && (
                  <span className="text-black rounded-3xl py-1 px-2 bg-[#f5a66c]">{version}</span>
                )}
                {clipboardIcon}
              </button>
            )}
          </ClipboardItem>
        ))}
      </div>
      <p className="text-gray-400 text-xs min-h-12">{description}</p>
      <p className="text-xs">
        <span className="text-gray-500 font-medium">Last Updated: &nbsp;</span>
        <span className="text-gray-400">{format(parseISO(updated), 'MMMM do, yyyy')}</span>
      </p>
    </div>
  )
}

export { StoreCard }
