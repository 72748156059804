import { FC } from 'react'
import { format, parseISO } from 'date-fns'
import { IoStorefrontOutline } from 'react-icons/io5'

import { Badge, InfoElement } from '@/components'
import { Account } from '@/models/account'
import { getBadgeVariantByStatus, toAddressString } from '@/utils'
import { Store } from '@/models/store'

import { StoreCard } from './StoreCard'

interface Props {
  account?: Account
  stores: Store[]
  isLoading: boolean
  onStoreSelected(_: Store): void
}

//TODO: add loading skeleton for stores
const AccountDetailsTabContent: FC<Props> = ({ account, stores, onStoreSelected, isLoading }) => {
  const badgeVariant = getBadgeVariantByStatus(account?.status)

  return (
    <div className="flex flex-col gap-6">
      <div className="p-6 rounded-lg border border-gray-100 flex divide-x-2 divide-gray-100 overflow-x-auto">
        <InfoElement className="grow shrink-0 pr-8" label="Company" value={account?.company} />
        <InfoElement className="grow shrink-0 pr-8 pl-8" label="Id" value={account?.id} />
        <InfoElement
          className="grow shrink-0 pr-8 pl-8"
          label="Created At"
          value={format(
            parseISO(account?.created_at ?? new Date().toISOString()),
            'MM/dd/yyyy - hh:mm:ss',
          )}
        />
        <InfoElement
          className="grow shrink-0 pr-8 pl-8"
          label="Last Update"
          value={format(
            parseISO(account?.updated_at ?? new Date().toISOString()),
            'MM/dd/yyyy - hh:mm:ss',
          )}
        />
        <InfoElement
          className="grow shrink-0 pr-8 pl-8"
          label="Status"
          value={
            <Badge className="w-fit text-sm" {...badgeVariant}>
              {account?.status ?? 'unspecified'}
            </Badge>
          }
        />
      </div>
      <div className="text-gray-800 font-medium">Contact Info</div>
      <div className="p-6 rounded-lg border border-gray-100 flex divide-x-2 divide-gray-100 overflow-x-auto">
        <InfoElement
          className="grow shrink-0 pr-8"
          label="Owner"
          value={`${account?.first_name} ${account?.last_name}`}
        />
        <InfoElement className="grow shrink-0 pr-8 pl-8" label="Phone" value={account?.phone} />
        <InfoElement className="grow shrink-0 pr-8 pl-8" label="Email" value={account?.email} />
        <InfoElement
          className="grow shrink-0 pr-8 pl-8"
          label="Address"
          value={toAddressString(account?.address)}
        />
      </div>
      {!isLoading && (
        <>
          <div className="text-gray-800 font-medium">Stores</div>
          {stores.length ? (
            <div className="grid grid-cols-[repeat(_auto-fill,minmax(400px,1fr)_)] items-stretch gap-6">
              {stores.map((store, index) => (
                <StoreCard
                  key={index}
                  store={store}
                  onEdit={() => {
                    onStoreSelected(store)
                  }}
                />
              ))}
            </div>
          ) : (
            <div className="flex flex-col items-center py-12">
              <IoStorefrontOutline className="text-[2.5rem] text-gray-400" />
              <h3 className="mt-2 text-sm font-semibold text-gray-900">No stores</h3>
              <p className="mt-1 text-sm text-gray-500">Get started by creating a new store.</p>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export { AccountDetailsTabContent }
