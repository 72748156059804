import { Outlet } from 'react-router-dom'

import { NavBar, SideBar } from './components'
import { Breadcrumb } from '../breadcrumb'

export const Layout = () => (
  <main className="h-dvh flex w-full">
    <SideBar />
    <div className="flex flex-col grow min-w-0">
      <NavBar />
      <div className="flex flex-col relative px-8 py-6 grow bg-gray-50 overflow-auto">
        <Breadcrumb />
        <Outlet />
      </div>
    </div>
  </main>
)
