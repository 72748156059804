import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ChevronRightIcon, PlusCircleIcon } from '@heroicons/react/24/outline'

import { Store } from '@/models/store'
import { AgGridApp, ButtonApp, Container, Spinner } from '@/components'

import { CreateOrEditStoreModal } from './components'
import { useGetStores } from './hooks'
import { BulkEditModal } from './components/BulkEditModal'
import { ROUTES_PATHS } from '@/routes'

export const Stores = () => {
  const {
    columnsStoresDefs,
    handleOpenModal,
    isOpenModal,
    handleCloseModal,
    store,
    stores,
    isLoading,
  } = useGetStores()
  const [showBulkModal, setShowBulkModal] = useState(false)
  const navigate = useNavigate()

  const goToStoreDetails = (store: Store) => {
    navigate(`${ROUTES_PATHS.STORES}/${store.id}`)
  }

  return (
    <>
      {isLoading && <Spinner />}
      <Container
        header={
          <div className="flex xs:flex-col-reverse md:flex-row md:justify-between gap-[15px]">
            <p className="text-2xl font-bold">Stores</p>

            <div className="flex justify-end gap-4">
              <ButtonApp
                handleClick={() => {
                  setShowBulkModal(true)
                }}
              >
                <div className="flex items-center gap-[10px]">
                  <span>Bulk Update</span>
                  <ChevronRightIcon className="text-gray-600 h-4 w-4" />
                </div>
              </ButtonApp>
              <ButtonApp isPrimary handleClick={handleOpenModal}>
                <div className="flex items-center gap-[10px]">
                  <PlusCircleIcon className="text-white h-[24px] w-[24px]" />
                  <span>Create Store</span>
                </div>
              </ButtonApp>
            </div>
          </div>
        }
      >
        <div className="flex flex-col w-full h-full overflow-auto">
          <AgGridApp
            rowData={stores}
            columnDefs={columnsStoresDefs}
            isShowPagination={true}
            isShowFilter={false}
            paginationPageSize={20}
            onRowDoubleClicked={(e) => {
              goToStoreDetails(e.data)
            }}
          />
          {isOpenModal && (
            <CreateOrEditStoreModal
              isOpenModal={isOpenModal}
              handleCloseModal={handleCloseModal}
              store={store as Store}
            />
          )}
        </div>
      </Container>
      <BulkEditModal
        isOpenModal={showBulkModal}
        handleCloseModal={() => {
          setShowBulkModal(false)
        }}
      />
    </>
  )
}
