import { Group } from '@/models/group'
import { ActionsColumn } from '@/components'
import { labels } from '@/constants/labels'
import { WorkflowStatus } from '@/models/workFlow'

type ColumnProps = { data: Group }

export default function Columns(
  handleEdit: (group: Group) => void,
  handleRedirectToInfoGroup: (id: string) => void,
) {
  return [
    {
      field: 'name',
      headerName: labels.name,
    },
    { field: 'description', headerName: labels.description },
    { field: 'artifactsBucketName', headerName: labels.artifactsBucketName },
    { field: 'apiLambdaSrcKey', headerName: labels.apiLambdaSrcKey },
    { field: 'apiLambdaVersion', headerName: labels.apiLambdaVersion },
    {
      field: 'actions',
      headerName: labels.actions,
      cellRenderer: (props: ColumnProps) => (
        <ActionsColumn
          handleEdit={() => handleEdit(props.data)}
          handleRedirectToInfoPage={() => handleRedirectToInfoGroup(props.data.id)}
          isShowEdit={
            props.data.workflowStatus === WorkflowStatus.SUCCEEDED ||
            props.data.workflowStatus === WorkflowStatus.FAILED
          }
        />
      ),
      filter: false,
      floatingFilter: false,
      sortable: false,
    },
  ]
}