import { lazy, Suspense } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Authenticator } from '@aws-amplify/ui-react'
import { Amplify } from 'aws-amplify'
import { ToastContainer } from 'react-toastify'
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import 'react-toastify/dist/ReactToastify.css'
import '@aws-amplify/ui-react/styles.css'

import { routes } from './routes'

const App = () => {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: import.meta.env.VITE_USER_POOL_ID || 'unknown',
        userPoolClientId: import.meta.env.VITE_CLIENT_ID || 'unknown',
        identityPoolId: import.meta.env.VITE_IDENTITY_POOL_ID || 'unknown',
        loginWith: {
          email: true,
        },
        signUpVerificationMethod: 'code',
        userAttributes: {
          email: {
            required: true,
          },
        },
        allowGuestAccess: false,
        passwordFormat: {
          minLength: 8,
          requireLowercase: true,
          requireUppercase: true,
          requireNumbers: true,
          requireSpecialCharacters: true,
        },
      },
    },
  })

  const router = createBrowserRouter(routes)

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 1,
      },
    },
    queryCache: new QueryCache({
      onError: (error, query) => {
        console.error(error, query)
      },
    }),
  })

  const showDevTool = process.env.NODE_ENV === 'development'

  return (
    <Authenticator hideSignUp>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme="colored"
      />
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        {showDevTool ? (
          <Suspense>
            <ReactQueryDevtools />
          </Suspense>
        ) : null}
      </QueryClientProvider>
    </Authenticator>
  )
}

const ReactQueryDevtools = lazy(() =>
  import('@tanstack/react-query-devtools').then((d) => {
    return {
      default: () =>
        d.ReactQueryDevtools({
          buttonPosition: 'bottom-left',
          position: 'bottom',
          initialIsOpen: false,
        }),
    }
  }),
)

export default App
