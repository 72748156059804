export const addressInitialValues = {
  address1: '',
  address2: '',
  city: '',
  country: '',
  country_code: '',
  province: '',
  province_code: '',
  zip: '',
}
