import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { AgGridApp, Container, FormControl, InputSelect, Spinner } from '@/components'
import { Record } from '@/models/record'
import { Group } from '@/models/group'
import { ROUTES_PATHS } from '@/routes'

import { useHome } from './hooks'
import ColumnsRecords from './data/columnsRecords'
import { RecordModal } from './components/RecordModal'
import { Store } from '@/models/store'

export const Home = () => {
  const {
    handleChangeGroupsLimitCount,
    handleChangeStoresLimitCount,
    selectedGroupsLimitCount,
    selectedStoresLimitCount,
    columnsGroupsDefs,
    selectedHistoryLimitCount,
    handleChangeHistoryLimitCount,
    dataSelectLimit,
    columnsStoresDefs,
    lastGroups,
    lastStores,
    records,
    isLoading,
  } = useHome()
  const navigate = useNavigate()
  const [selectedRecord, setSelectedRecord] = useState<Record | undefined>(undefined)

  const columnsRecordsDefs = ColumnsRecords(setSelectedRecord)

  const goToGroupDetails = (group: Group) => {
    navigate(`${ROUTES_PATHS.GROUPS}/${group?.id}`)
  }

  const goToStoreDetails = (store: Store) => {
    navigate(`${ROUTES_PATHS.STORES}/${store?.id}`)
  }

  return (
    <div className="grid grid-cols-1 gap-[20px] pb-[40px]">
      {isLoading && <Spinner />}
      <div className="grid xs:grid-cols-1 lg:grid-cols-2 gap-[20px]">
        <Container>
          <div className="flex flex-col gap-[20px] md:pr-[40px] w-full ">
            <p className="text-[25px] font-bold">Last {selectedGroupsLimitCount.id} Groups</p>
            <div className="flex flex-col w-full gap-[20px]">
              <div className="flex justify-end">
                <div className="w-[170px]">
                  <FormControl label="Select a limit">
                    <InputSelect
                      listOptions={dataSelectLimit}
                      valueOption={selectedGroupsLimitCount}
                      handleChange={handleChangeGroupsLimitCount}
                    />
                  </FormControl>
                </div>
              </div>
              <AgGridApp
                rowData={lastGroups}
                columnDefs={columnsGroupsDefs}
                isShowPagination={false}
                isShowFilter={false}
                suppressCellFocus
                paginationPageSize={20}
                onRowDoubleClicked={(e) => {
                  goToGroupDetails(e.data)
                }}
              />
            </div>
          </div>
        </Container>
        <Container>
          <div className="flex flex-col gap-[20px] md:pr-[40px] w-full ">
            <p className="text-[25px] font-bold">Last {selectedStoresLimitCount.id} Stores</p>
            <div className="flex flex-col w-full gap-[20px]">
              <div className="flex justify-end">
                <div className="w-[170px]">
                  <FormControl label="Select a limit">
                    <InputSelect
                      listOptions={dataSelectLimit}
                      valueOption={selectedStoresLimitCount}
                      handleChange={handleChangeStoresLimitCount}
                    />
                  </FormControl>
                </div>
              </div>
              <AgGridApp
                rowData={lastStores}
                columnDefs={columnsStoresDefs}
                isShowPagination={false}
                isShowFilter={false}
                suppressCellFocus
                paginationPageSize={20}
                onRowDoubleClicked={(e) => {
                  goToStoreDetails(e.data)
                }}
              />
            </div>
          </div>
        </Container>
      </div>
      <Container className="grow-0 overflow-[unset]">
        <div className="flex flex-col gap-[20px] md:pr-[40px] w-full ">
          <p className="text-[25px] font-bold">
            Last {selectedHistoryLimitCount.id} Changes History
          </p>
          <div className="flex flex-col w-full gap-[20px]">
            <div className="flex justify-end">
              <div className="w-[170px]">
                <FormControl label="Select a limit">
                  <InputSelect
                    listOptions={dataSelectLimit}
                    valueOption={selectedHistoryLimitCount}
                    handleChange={handleChangeHistoryLimitCount}
                  />
                </FormControl>
              </div>
            </div>
            <AgGridApp
              rowData={records}
              columnDefs={columnsRecordsDefs}
              isShowPagination={false}
              isShowFilter={false}
              suppressCellFocus
              paginationPageSize={20}
            />
          </div>
        </div>
      </Container>
      <RecordModal
        record={selectedRecord}
        handleCloseModal={() => {
          setSelectedRecord(undefined)
        }}
      />
    </div>
  )
}
