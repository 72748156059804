import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import { PlusCircleIcon } from '@heroicons/react/24/outline'

import { ButtonApp, Container, Spinner } from '@/components'
import { Account, CreateAccountRequest } from '@/models/account'

import { useCreateGroupAccount, useGetGroup, useUpdateGroupAccount } from './hooks'
import {
  GroupDetailsTabContent,
  GroupRawTabContent,
  GroupInfraTabContent,
  CreateAndEditAccountModal,
  GroupHistoryTabContent,
} from './components'
import { columnsAccounts } from './data/columnsAccounts'
import { GroupAccountsTabContent } from './components/GroupAccountsTabContent'

const tabListHeaders = ['Details', 'Infra', 'Accounts', 'History', 'Raw']

export const ShowGroup = () => {
  const navigate = useNavigate()
  const { group, isLoading, groupAccounts, groupHistory } = useGetGroup()
  const [showModal, setShowModal] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState<Account | undefined>(undefined)
  const { createAccount, isCreating } = useCreateGroupAccount({
    groupId: group?.id ?? '',
    onSuccess: () => {
      setShowModal(false)
      setSelectedAccount(undefined)
    },
  })
  const { updateAccount, isUpdating } = useUpdateGroupAccount({
    groupId: group?.id ?? '',
    onSuccess: () => {
      setSelectedAccount(undefined)
      setShowModal(false)
    },
  })

  const columnsAccountsDefs = columnsAccounts(group?.id ?? '', navigate, setSelectedAccount)

  const handleSubmitAccount = async (data: CreateAccountRequest) => {
    data.id ? updateAccount(data) : createAccount(data)
  }

  return (
    <div className="flex flex-col gap-[20px] grow overflow-hidden">
      {isLoading && <Spinner />}
      <Container
        header={
          <div className="flex grow overflow-hidden xs:flex-col-reverse md:flex-row md:justify-between gap-[15px]">
            <p className="text-2xl font-bold">{group?.name}</p>
            <div className="flex justify-end">
              <ButtonApp
                isPrimary
                handleClick={() => {
                  setSelectedAccount(undefined)
                  setShowModal(true)
                }}
              >
                <div className="flex items-center gap-[10px]">
                  <PlusCircleIcon className="text-white h-[24px] w-[24px]" />
                  <p>Create an Account</p>
                </div>
              </ButtonApp>
            </div>
          </div>
        }
      >
        <TabGroup className="grow flex flex-col gap-6 max-w-full">
          <TabList className="flex gap-2 items-baseline border-b border-gray-100 px-8 mx-2">
            {tabListHeaders.map((header, index) => (
              <Tab
                key={index}
                className="-mb-[1px] focus-visible:outline-none py-2 px-4 text-gray-500 text-sm rounded-[4px] rounded-b-none border border-gray-100 bg-[#F5F7FA] data-[selected]:bg-white data-[selected]:border-b-white"
              >
                {header}
              </Tab>
            ))}
          </TabList>
          <TabPanels className="grow overflow-auto px-4">
            <TabPanel>
              <GroupDetailsTabContent group={group} />
            </TabPanel>
            <TabPanel>
              <GroupInfraTabContent group={group} />
            </TabPanel>
            <TabPanel>
              <GroupAccountsTabContent
                group={group}
                groupAccounts={groupAccounts}
                columnsAccountsDefs={columnsAccountsDefs}
              />
            </TabPanel>
            <TabPanel>
              <GroupHistoryTabContent history={groupHistory} />
            </TabPanel>
            <TabPanel>
              <GroupRawTabContent group={group} />
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </Container>
      <CreateAndEditAccountModal
        isOpenModal={showModal || !!selectedAccount}
        isSubmitting={isCreating || isUpdating}
        handleSubmitAccount={handleSubmitAccount}
        account={selectedAccount}
        handleCloseModal={() => {
          setShowModal(false)
          setSelectedAccount(undefined)
        }}
      />
    </div>
  )
}
