import { useCallback } from 'react'
import { useQuery } from '@tanstack/react-query'

import { GroupService } from '@/services/group'
import { Account } from '@/models/account'

export const useAccount = (groupId?: string, accountId?: string) => {
  const queryKey = ['account', accountId]

  const getAccount = useCallback(async () => {
    const response = await GroupService.getGroupAccount(groupId as string, accountId as string)
    return response.data as Account
  }, [groupId])

  const { data, isLoading, refetch } = useQuery({
    queryKey,
    queryFn: getAccount,
    enabled: !!groupId && !!accountId,
    refetchInterval: 5000,
  })

  return { account: data, isLoading, refetch }
}
