import { classNames } from '@/utils'
import { FC, PropsWithChildren, ReactNode } from 'react'

type ContainerProps = {
  header?: ReactNode
  className?: string
}

export const Container: FC<PropsWithChildren<ContainerProps>> = ({
  header,
  children,
  className,
}) => {
  return (
    <div
      className={classNames(
        'flex flex-col rounded-xl border border-gray-100 w-full bg-white grow overflow-auto',
        className,
      )}
    >
      {!!header && <div className="w-full py-6 border-b border-b-gray-100 px-6">{header}</div>}
      <div className="flex p-6 grow overflow-hidden">{children}</div>
    </div>
  )
}
