import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  ButtonApp,
  ConfirmDialog,
  FormApp,
  FormControl,
  SelectField,
  TextAreaInput,
} from '@/components'
import { Group } from '@/models/group'
import { classNames, getVersion } from '@/utils'

import { backendResourcesSchema } from '../data/schema'
import { useArtifacts } from '../../groups/hooks'
import { BackendResourcesFormData } from '../data/types'
import { useUpdateGroup } from '../hooks/useUpdateGroup'

const BackendResourcesForm: FC<{ group?: Group }> = ({ group }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<BackendResourcesFormData>({
    defaultValues: getBackendResourcesValuesFromGroup(group),
    values: getBackendResourcesValuesFromGroup(group),
    mode: 'onChange',
    resolver: yupResolver(backendResourcesSchema),
    disabled: !group,
  })
  const [dataToUpdate, setDataToUpdate] = useState<BackendResourcesFormData | undefined>(undefined)
  const { updateGroup, isUpdating } = useUpdateGroup({
    onSuccess: () => setDataToUpdate(undefined),
  })

  const { artifacts, isLoading: isLoadingArtifacts } = useArtifacts()

  const lambdaGraphqlSrcKeyOptions =
    artifacts?.['e-com-srv']?.map((artifact) => ({
      label: getVersion(artifact) ?? artifact,
      value: `e-com-srv/${artifact}`,
    })) ?? []
  const lambdaWebHookGroupSrcKeyOptions =
    artifacts?.['api-srv']?.map((artifact) => ({
      label: getVersion(artifact) ?? artifact,
      value: `api-srv/${artifact}`,
    })) ?? []
  const appWorkerOptions =
    artifacts?.['e-com-app-worker']?.map((artifact) => ({
      label: getVersion(artifact) ?? artifact,
      value: `e-com-app-worker/${artifact}`,
    })) ?? []
  const notificationWorkerOptions =
    artifacts?.['e-com-notification-worker']?.map((artifact) => ({
      label: getVersion(artifact) ?? artifact,
      value: `e-com-notification-worker/${artifact}`,
    })) ?? []
  const orderWorkerOptions =
    artifacts?.['e-com-order-worker']?.map((artifact) => ({
      label: getVersion(artifact) ?? artifact,
      value: `e-com-order-worker/${artifact}`,
    })) ?? []

  const renderItemTemplate = ({
    value,
    fieldType,
  }: {
    value: string
    fieldType: 'api' | 'worker'
  }) => (
    <span
      className={classNames('text-white rounded-full px-2 text-xs w-fit h-6 flex items-center', {
        'bg-[#2E59A3]': fieldType === 'api',
        'bg-[#288F58]': fieldType === 'worker',
      })}
    >
      {getVersion(value)}
    </span>
  )

  return (
    <>
      <FormApp
        handleCancel={() => {}}
        handleSubmit={handleSubmit}
        onSubmit={(data: BackendResourcesFormData) => {
          setDataToUpdate(data)
        }}
        defaultButtonsHidden
        outsideModal
      >
        <div className="flex flex-col gap-6">
          <div className="flex items-center justify-between">
            <span className="text-gray-800 font-medium">Backend Resources</span>
            <ButtonApp
              type="submit"
              width="md:min-w-[160px] w-fit"
              className="h-12 flex items-center gap-2 text-sm"
              isDisabled={['REQUESTED', 'IN_PROGRESS'].includes(group?.workflowStatus ?? '')}
            >
              <span>Deploy changes</span>
            </ButtonApp>
          </div>
          <div className="grid grid-cols-2 gap-6">
            <fieldset className="rounded-lg border border-[#F0F2F7] p-6 flex flex-col gap-6 self-start">
              <label>API</label>
              <div className="grid grid-cols-2 gap-6">
                <FormControl label="GraphQL">
                  <SelectField
                    name="lambdaGraphqlSrcKey"
                    register={register}
                    options={lambdaGraphqlSrcKeyOptions}
                    placeholder="Select version"
                    isLoading={isLoadingArtifacts}
                    control={control}
                    valueTemplate={(value) => renderItemTemplate({ value, fieldType: 'api' })}
                    itemTemplate={(value) => renderItemTemplate({ value, fieldType: 'api' })}
                    optionClassName="data-[focus]:bg-blue-50 data-[selected]:bg-blue-50 hover:bg-blue-50"
                    checkIconClassName="text-blue-100 group-data-[selected]:text-[#2E59A3]"
                  />
                </FormControl>
                <FormControl label="Webhook">
                  <SelectField
                    name="lambdaWebHookGroupSrcKey"
                    register={register}
                    options={lambdaWebHookGroupSrcKeyOptions}
                    placeholder="Select version"
                    isLoading={isLoadingArtifacts}
                    control={control}
                    valueTemplate={(value) => renderItemTemplate({ value, fieldType: 'api' })}
                    itemTemplate={(value) => renderItemTemplate({ value, fieldType: 'api' })}
                    optionClassName="data-[focus]:bg-blue-50 data-[selected]:bg-blue-50 hover:bg-blue-50"
                    checkIconClassName="text-blue-100 group-data-[selected]:text-[#2E59A3]"
                  />
                </FormControl>
              </div>
            </fieldset>
            <fieldset className="rounded-lg border border-[#F0F2F7] p-6 flex flex-col gap-6 self-start">
              <label>Workers</label>
              <div className="grid grid-cols-3 gap-6">
                <FormControl label="App">
                  <SelectField
                    name="pubsubs.app.lambdaSrcKey"
                    register={register}
                    options={appWorkerOptions}
                    placeholder="Select version"
                    isLoading={isLoadingArtifacts}
                    control={control}
                    valueTemplate={(value) => renderItemTemplate({ value, fieldType: 'worker' })}
                    itemTemplate={(value) => renderItemTemplate({ value, fieldType: 'worker' })}
                    optionClassName="data-[focus]:bg-green-50 data-[selected]:bg-green-50 hover:bg-green-50"
                    checkIconClassName="text-green-100 group-data-[selected]:text-[#288F58]"
                  />
                </FormControl>
                <FormControl label="Notification">
                  <SelectField
                    name="pubsubs.notification.lambdaSrcKey"
                    register={register}
                    options={notificationWorkerOptions}
                    placeholder="Select version"
                    isLoading={isLoadingArtifacts}
                    control={control}
                    valueTemplate={(value) => renderItemTemplate({ value, fieldType: 'worker' })}
                    itemTemplate={(value) => renderItemTemplate({ value, fieldType: 'worker' })}
                    optionClassName="data-[focus]:bg-green-50 data-[selected]:bg-green-50 hover:bg-green-50"
                    checkIconClassName="text-green-100 group-data-[selected]:text-[#288F58]"
                  />
                </FormControl>
                <FormControl label="Order">
                  <SelectField
                    name="pubsubs.order.lambdaSrcKey"
                    register={register}
                    options={orderWorkerOptions}
                    placeholder="Select version"
                    isLoading={isLoadingArtifacts}
                    control={control}
                    valueTemplate={(value) => renderItemTemplate({ value, fieldType: 'worker' })}
                    itemTemplate={(value) => renderItemTemplate({ value, fieldType: 'worker' })}
                    optionClassName="data-[focus]:bg-green-50 data-[selected]:bg-green-50 hover:bg-green-50"
                    checkIconClassName="text-green-100 group-data-[selected]:text-[#288F58]"
                  />
                </FormControl>
              </div>
            </fieldset>
            <fieldset className="rounded-lg border border-[#F0F2F7] p-6 flex flex-col gap-6 self-start">
              <label>Pupsub Event Filter</label>
              <FormControl
                label="App"
                messageError={errors.pubsubs?.app?.filterPolicyJson?.event_type?.message}
              >
                <TextAreaInput
                  rows={7}
                  name="pubsubs.app.filterPolicyJson.event_type"
                  placeholder="Enter app event types"
                  register={register}
                  isError={!!errors.pubsubs?.app?.filterPolicyJson?.event_type?.message}
                />
              </FormControl>
              <FormControl
                label="Notification"
                messageError={errors.pubsubs?.notification?.filterPolicyJson?.event_type?.message}
              >
                <TextAreaInput
                  rows={7}
                  name="pubsubs.notification.filterPolicyJson.event_type"
                  placeholder="Enter notification event types"
                  register={register}
                  isError={!!errors.pubsubs?.notification?.filterPolicyJson?.event_type?.message}
                />
              </FormControl>
              <FormControl
                label="Order"
                messageError={errors.pubsubs?.order?.filterPolicyJson?.event_type?.message}
              >
                <TextAreaInput
                  rows={7}
                  name="pubsubs.order.filterPolicyJson.event_type"
                  placeholder="Enter order event types"
                  register={register}
                  isError={!!errors.pubsubs?.order?.filterPolicyJson?.event_type?.message}
                />
              </FormControl>
            </fieldset>
            <fieldset className="rounded-lg border border-[#F0F2F7] p-6 flex flex-col gap-6 self-start">
              <label>IDP</label>
              <FormControl messageError={errors.cognitoConfig?.message}>
                <TextAreaInput
                  rows={7}
                  name="cognitoConfig"
                  placeholder="Enter cognito config"
                  register={register}
                  isError={!!errors.cognitoConfig?.message}
                />
              </FormControl>
            </fieldset>
          </div>
        </div>
      </FormApp>
      <ConfirmDialog
        visible={!!dataToUpdate}
        confirmText="Are you sure yo want to deploy these changes?"
        onHide={() => setDataToUpdate(undefined)}
        onConfirm={() => updateGroup(dataToUpdate!)}
        isLoading={isUpdating}
      />
    </>
  )
}

const getBackendResourcesValuesFromGroup = (group?: Group): BackendResourcesFormData | undefined =>
  group
    ? {
        ...group,
        cognitoConfig: JSON.stringify(group.cognitoConfig, null, 2),
        pubsubs: {
          app: {
            ...group.pubsubs?.app,
            filterPolicyJson: {
              ...group.pubsubs?.app?.filterPolicyJson,
              event_type: JSON.stringify(group.pubsubs?.app?.filterPolicyJson.event_type, null, 2),
            },
          },
          notification: {
            ...group.pubsubs?.notification,
            filterPolicyJson: {
              ...group.pubsubs?.notification?.filterPolicyJson,
              event_type: JSON.stringify(
                group.pubsubs?.notification?.filterPolicyJson.event_type,
                null,
                2,
              ),
            },
          },
          order: {
            ...group.pubsubs?.order,
            filterPolicyJson: {
              ...group.pubsubs?.order?.filterPolicyJson,
              event_type: JSON.stringify(
                group.pubsubs?.order?.filterPolicyJson.event_type,
                null,
                2,
              ),
            },
          },
        },
      }
    : undefined

export { BackendResourcesForm }
