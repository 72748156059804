import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { OptionType } from '@/models/optionSelect'
import { ApiRequestException } from '@/models/apiResponse'
import { Record } from '@/models/record'
import { Group } from '@/models/group'
import { Store } from '@/models/store'
import { RecordService } from '@/services/record'
import { GroupService } from '@/services/group'
import { StoreService } from '@/services/store'
import { useGetAllGroups, useNotify } from '@/hooks'
import { dataSelectLimit, selectedLimitInitial } from '@/pages/home/data/dataSelectLimit'
import ColumnsStores from '@/pages/home/data/columnsStores'
import ColumnsGroups from '@/pages/home/data/columnsGroups'

export const useHome = () => {
  const [lastGroups, setLatsGroups] = useState<Group[]>([])
  const [lastStores, setLastStores] = useState<Store[]>([])
  const [selectedGroupsLimitCount, setSelectedGroupsLimitCount] =
    useState<OptionType>(selectedLimitInitial)
  const [selectedStoresLimitCount, setSelectedStoresLimitCount] =
    useState<OptionType>(selectedLimitInitial)
  const [selectedHistoryLimitCount, setSelectedHistoryLimitCount] =
    useState<OptionType>(selectedLimitInitial)
  const [records, setRecords] = useState<Record[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { notifyError } = useNotify()
  const { groups } = useGetAllGroups()
  const navigate = useNavigate()

  const handleGetLastGroups = async () => {
    setIsLoading(true)
    try {
      const response = await GroupService.getLastGroups(Number(selectedGroupsLimitCount.id))
      if (response.data) {
        setLatsGroups(response.data as Group[])
      }
    } catch (error: unknown) {
      const errorResponse = error as ApiRequestException
      notifyError(errorResponse?.error?.content ?? 'There is an error')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleGetLastGroups()
  }, [selectedGroupsLimitCount])

  const handleGetLastStores = async () => {
    setIsLoading(true)
    try {
      const response = await StoreService.getLastStores(Number(selectedStoresLimitCount.id))
      setLastStores(response.data as Store[])
    } catch (error: unknown) {
      const errorResponse = error as ApiRequestException
      notifyError(errorResponse?.error?.content ?? 'There is an error')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleGetLastStores()
  }, [selectedStoresLimitCount])

  const getLastChanges = async () => {
    setIsLoading(true)
    try {
      const response = await RecordService.getLastChangesByLimit(
        Number(selectedHistoryLimitCount.id),
      )
      setRecords(response.data as Record[])
    } catch (error: unknown) {
      const errorResponse = error as ApiRequestException
      notifyError(errorResponse?.error?.content ?? 'There is an error')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getLastChanges()
  }, [selectedHistoryLimitCount])

  const handleChangeGroupsLimitCount = (option: OptionType) => {
    setSelectedGroupsLimitCount(option)
  }

  const handleChangeStoresLimitCount = (option: OptionType) => {
    setSelectedStoresLimitCount(option)
  }

  const handleChangeHistoryLimitCount = (option: OptionType) => {
    setSelectedHistoryLimitCount(option)
  }

  const handleGetGroupName = (groupId: string) => groups.find(({ id }) => id === groupId)?.name

  const navigateToStore = (storeId: string) => {
    navigate(`/stores/${storeId}`)
  }

  const navigateToGroup = (groupId: string) => {
    navigate(`/groups/${groupId}`)
  }

  const columnsStoresDefs = ColumnsStores(handleGetGroupName, navigateToStore)

  const columnsGroupsDefs = ColumnsGroups(navigateToGroup)

  return {
    handleChangeGroupsLimitCount,
    handleChangeStoresLimitCount,
    lastStores,
    lastGroups,
    isLoading,
    handleChangeHistoryLimitCount,
    selectedHistoryLimitCount,
    dataSelectLimit,
    records,
    columnsGroupsDefs,
    columnsStoresDefs,
    selectedGroupsLimitCount,
    selectedStoresLimitCount,
  }
}
