import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { Account, CreateAccountRequest } from '@/models/account'
import { addressInitialValues } from '@/data'

import { accountSchema } from '../data/schema'

export const useCreateAndEditAccountModal = (args?: Args) => {
  const account = args?.account

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<CreateAccountRequest>({
    defaultValues: {
      id: undefined,
      company: '',
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      address: addressInitialValues,
    },
    mode: 'onChange',
    resolver: yupResolver(accountSchema),
  })

  useEffect(() => {
    setValue('id', account?.id)
    setValue('company', account?.company ?? '')
    setValue('first_name', account?.first_name ?? '')
    setValue('last_name', account?.last_name ?? '')
    setValue('email', account?.email ?? '')
    setValue('phone', account?.phone ?? '')
    setValue('address', account?.address ?? addressInitialValues)
  }, [account])

  return {
    handleSubmit,
    errors,
    register,
    reset,
  }
}

type Args = { account?: Account }
