import { ApiResponse } from '@/models/apiResponse.ts'

export function makeResponse<T>(response: any): ApiResponse<T> {
  let result: ApiResponse<T> = { status: response?.status }
  if (response?.data != null && typeof response?.data !== 'undefined') {
    result = {
      ...result,
      data: response?.data === '' ? null : response?.data,
    }
  } else {
    result = {
      ...result,
      data: response?.data === '' ? null : response,
    }
  }
  return result
}