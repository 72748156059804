import { FC } from 'react'
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import ReactJsonViewCompare from 'react-json-view-compare'

import { Record } from '@/models/record'
import { ButtonApp, ModalApp } from '@/components'

interface RecordModalProps {
  record?: Record
  handleCloseModal(): void
}

const tabListHeaders = ['Before Value', 'After Value', 'Comparison']

const RecordModal: FC<RecordModalProps> = ({ record, handleCloseModal }) => {
  if (!record) return null

  return (
    <ModalApp
      isOpenModal={!!record}
      handleCloseModal={handleCloseModal}
      title="Latest Updates"
      maxHeight="xs:max-h-[820px] md:max-h-[800px]"
      maxWidth="xs:max-w-[340px] md:max-w-[1024px]"
    >
      <div className="w-full flex flex-col min-h-0">
        <div className="p-8 min-h-[60vh] max-h-[60vh] overflow-hidden flex">
          <TabGroup className="grow flex flex-col gap-6 max-w-full">
            <TabList className="flex gap-2 items-baseline border-b border-gray-100 px-8 mx-2">
              {tabListHeaders.map((header, index) => (
                <Tab
                  key={index}
                  className="-mb-[1px] focus-visible:outline-none py-2 px-4 text-gray-500 text-sm rounded-[4px] rounded-b-none border border-gray-100 bg-[#F5F7FA] data-[selected]:bg-white data-[selected]:border-b-white"
                >
                  {header}
                </Tab>
              ))}
            </TabList>
            <TabPanels className="grow overflow-auto px-4">
              <TabPanel className="h-full">
                <pre className="h-full rounded-lg border border-gray-100 p-6 text-gray-500 text-sm overflow-x-auto">
                  {JSON.stringify(parseValue(record?.currentValue), null, 2)}
                </pre>
              </TabPanel>
              <TabPanel className="h-full">
                <pre className="h-full rounded-lg border border-gray-100 p-6 text-gray-500 text-sm overflow-x-auto">
                  {JSON.stringify(parseValue(record?.newValue), null, 2)}
                </pre>
              </TabPanel>
              <TabPanel className="h-full">
                <div className="h-full w-full">
                  <ReactJsonViewCompare
                    oldData={parseValue(record?.currentValue)}
                    newData={parseValue(record?.newValue)}
                  />
                </div>
              </TabPanel>
            </TabPanels>
          </TabGroup>
        </div>
        <div className="flex justify-end w-full py-6 px-8 border-t border-[#F0F2F7]">
          <ButtonApp
            handleClick={handleCloseModal}
            isPrimary={false}
            width="md:min-w-[160px] w-fit"
            className="h-12"
          >
            Close
          </ButtonApp>
        </div>
      </div>
    </ModalApp>
  )
}

const parseValue = (value?: string) => {
  const parsedValue = JSON.parse(value ?? '{}')

  return {
    ...parsedValue,
    ...(parsedValue.outputs ? { outputs: JSON.parse(parsedValue.outputs) } : {}),
  }
}

export { RecordModal }
