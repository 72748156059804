import { ReactNode } from 'react'
import { XMarkIcon } from '@heroicons/react/24/solid'

type ModalProps = {
  isOpenModal: boolean
  handleCloseModal: () => void
  children: ReactNode
  className?: string
  maxHeight?: string
  maxWidth?: string
  bottom?: string
  title?: string
}

export const ModalApp = ({
  isOpenModal,
  handleCloseModal,
  children,
  className,
  maxHeight = 'xs:max-h-[650px] md:max-h-[600px]',
  maxWidth = 'xs:max-w-[340px] md:max-w-[800px]',
  bottom,
  title,
}: ModalProps) => {
  return (
    <>
      <div
        className={`fixed top-0 left-0 right-0 bg-black opacity-25 z-[2001] ${bottom ?? 'bottom-0'} ${isOpenModal ? 'd-block' : 'hidden'}`}
        onClick={handleCloseModal}
      />
      <div
        className={`fixed top-[50%] left-[50%] bg-white z-[2001] h-auto min-h-[200px] translate-y-[-50%] translate-x-[-50%] overflow-auto rounded-[20px] w-full ${maxHeight} ${className} ${maxWidth} ${isOpenModal ? 'd-block' : 'hidden'}`}
        style={{
          scrollbarWidth: 'none',
        }}
      >
        <div className="flex flex-col">
          <div className="flex justify-between items-center w-full px-8 py-6 border-b border-[#F0F2F7]">
            <span className="text-[#1A2333] text-xl font-bold">{title}</span>
            <XMarkIcon
              className="text-black h-6 w-6 cursor-pointer transition ease-in-out delay-80 hover:scale-105"
              onClick={handleCloseModal}
            />
          </div>
          <div className="flex flex-col min-w-[-300px] w-full">{children}</div>
        </div>
      </div>
    </>
  )
}
