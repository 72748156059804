import { ComponentProps, FC, ReactNode } from 'react'
import { PiCopySimple } from 'react-icons/pi'
import { toast } from 'react-toastify'

import { classNames, copyToClipboard } from '@/utils'

type Props = ClipboardItemProps | HeadlessClipboardItemProps

const ClipboardItem: FC<Props> = (componentProps) => {
  const handleClick = () => {
    copyToClipboard(componentProps.value, () => {
      toast.dark('Copied to clipboard', {
        closeButton: false,
        position: 'bottom-center',
        autoClose: 1000,
        className: 'min-h-0',
        bodyClassName: 'm-0 p-1',
      })
    })
  }

  if (componentProps.headless)
    return componentProps.children({ onClick: handleClick, clipboardIcon: <ClipboardIcon /> })

  const { label, className, ...props } = componentProps

  return (
    <button
      className={classNames(
        'py-1 px-3 border rounded-2xl text-xs flex items-center gap-2 text-gray-400 cursor-pointer',
        className,
      )}
      onClick={handleClick}
      {...props}
    >
      <span>{label}</span>
      <ClipboardIcon />
    </button>
  )
}

const ClipboardIcon = () => <PiCopySimple className="text-base" />

type ClipboardItemProps = ComponentProps<'button'> & {
  label: string
  value: string
  headless?: never
  children?: never
}

type HeadlessClipboardItemProps = {
  label?: never
  value: string
  headless: true
  children(_: { onClick: () => void; clipboardIcon: ReactNode }): ReactNode
}

export { ClipboardItem }
