export const labels = {
  name: 'Name',
  description: 'Description',
  artifactsBucketName: 'Artifacts Bucket Name',
  apiLambdaSrcKey: 'Api Lambda Src Key',
  apiLambdaVersion: 'Api Lambda Version',
  group: 'Group',
  stores: 'Stores',
  currentValue: 'Before value',
  newValue: 'Latest value',
  date: 'Date of change',
  typeOfFile: 'Type of Field',
  id: 'Id',
  actions: 'Actions',
}
