import { ComponentProps } from 'react'

import { classNames } from '@/utils'

interface TextAreaInputProps extends ComponentProps<'textarea'> {
  name: string
  isDisabled?: boolean
  placeholder?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register?: any
  isError?: boolean
  toBeParsed?: boolean
}
export const TextAreaInput = ({
  name,
  isDisabled,
  placeholder,
  register,
  isError,
  rows = 4,
  ...props
}: TextAreaInputProps) => (
  <textarea
    className={classNames(
      'border-[1px] rounded-[6px] p-[20px] text-secondary text-[14px] border-gray-200 focus:border-gray-300 outline-none',
      { 'border-red': isError },
    )}
    {...register(name)}
    disabled={isDisabled}
    placeholder={placeholder}
    rows={rows}
    {...props}
  />
)
