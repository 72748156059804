import * as yup from 'yup'

import { addressSchema } from '@/schemas'

export const accountSchema = yup.object({
  id: yup.string().optional(),
  company: yup.string().required('Company is required'),
  first_name: yup.string().required('Domain is required'),
  last_name: yup.string().required('Last name is required'),
  email: yup.string().required('Email is required'),
  phone: yup.string().required('Phone is required'),
  address: addressSchema.required('Address is required'),
})

const filterPolicyJsonSchema = yup.object({
  event_type: yup.string().test('is-valid-event-type', 'Must be an array of string', (value) => {
    if (!value) return true
    try {
      const parsedValue = JSON.parse(value)
      if (!Array.isArray(parsedValue)) return false
      if (parsedValue.some((value) => typeof value !== 'string')) return false
      return true
    } catch (error) {
      return false
    }
  }),
})

const pubSubSchema = yup.object({
  name: yup.string().optional(),
  lambdaSrcKey: yup.string().optional(),
  filterPolicyJson: filterPolicyJsonSchema.required('Filter policy is required'),
})

const cognitoCustomAttributeSchema = yup.object({
  name: yup.string().required('Custom attribute name is required'),
  mutable: yup.boolean().required('Mutable is required'),
  minLen: yup.number().optional(),
  maxLen: yup.number().optional(),
})

const cognitoRoleSchema = yup.object({
  roleName: yup.string().required('Role name is required'),
  description: yup.string().optional(),
})

const cognitoConfigSchema = yup.object({
  customAttributes: yup.array().of(cognitoCustomAttributeSchema).optional().default([]),
  defaultAttributes: yup
    .array()
    .of(yup.string().required('Default attribute must be a string'))
    .optional()
    .default([]),
  roles: yup.array().of(cognitoRoleSchema).optional().default([]),
})

export const backendResourcesSchema = yup.object({
  id: yup.string().required('Group ID is required'),
  apiLambdaSrcKey: yup.string().optional(),
  apiLambdaVersion: yup.string().optional(),
  artifactsBucketName: yup.string().optional(),
  description: yup.string().required('Description is required'),
  name: yup.string().required('Name is required'),
  workflowId: yup.string().optional(),
  workflowStatus: yup.string().required('Workflow status is required'),
  created: yup.string().required('Creation date is required'),
  updated: yup.string().required('Update date is required'),
  outputs: yup.string().optional(),
  domain: yup.string().optional(),
  totalAccounts: yup.number().required('Total accounts are required'),
  totalStores: yup.number().required('Total stores are required'),
  lambdaGraphqlSrcKey: yup.string().required('Lambda GraphQL source key is required'),
  lambdaWebHookGroupSrcKey: yup.string().required('Lambda WebHook group source key is required'),
  pubsubs: yup.object().shape({
    order: pubSubSchema,
    notification: pubSubSchema,
    app: pubSubSchema,
  }),
  cognitoConfig: yup
    .string()
    .test('is-valid-cognito-config', 'Must be a valid cognito configuration', (value) => {
      if (!value) return true
      try {
        const parsedValue = JSON.parse(value)
        return cognitoConfigSchema.isValidSync(parsedValue)
      } catch (error) {
        return false
      }
    }),
})
