import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form'

import { InputApp } from '../inputs/inputApp'
import { FormControl } from '../formControl'

const AddressField = ({ parentFieldName, errors, register }: Props) => {
  const parentFieldFullName = parentFieldName ? parentFieldName + '.' : ''

  return (
    <div className="grid grid-cols-3 gap-4">
      <FormControl label="Address 1" isRequired messageError={errors?.address1?.message}>
        <InputApp
          name={`${parentFieldFullName}address1`}
          placeholder="Address 1"
          register={register}
          isError={!!errors?.address1?.message}
        />
      </FormControl>
      <FormControl label="Address 2" messageError={errors?.address2?.message}>
        <InputApp
          name={`${parentFieldFullName}address2`}
          placeholder="Address 2"
          register={register}
          isError={!!errors?.address2?.message}
        />
      </FormControl>
      <FormControl label="City" isRequired messageError={errors?.city?.message}>
        <InputApp
          name={`${parentFieldFullName}city`}
          placeholder="City"
          register={register}
          isError={!!errors?.city?.message}
        />
      </FormControl>
      <FormControl label="State Code" isRequired messageError={errors?.province_code?.message}>
        <InputApp
          name={`${parentFieldFullName}province_code`}
          placeholder="State Code"
          register={register}
          isError={!!errors?.province_code?.message}
        />
      </FormControl>
      <FormControl label="Zip" isRequired messageError={errors?.zip?.message}>
        <InputApp
          name={`${parentFieldFullName}zip`}
          placeholder="Zip"
          register={register}
          isError={!!errors?.zip?.message}
        />
      </FormControl>
      <FormControl label="Country Code" isRequired messageError={errors?.country_code?.message}>
        <InputApp
          name={`${parentFieldFullName}country_code`}
          placeholder="Country Code"
          register={register}
          isError={!!errors?.country_code?.message}
        />
      </FormControl>
    </div>
  )
}

type Props = {
  parentFieldName?: string
  errors?:
    | Merge<
        FieldError,
        FieldErrorsImpl<{
          address1: string
          address2: string
          city: string
          zip: string
          province: string
          province_code: string
          country: string
          country_code: string
          __typename: string
        }>
      >
    | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register?: any
}

export { AddressField }
