import { FC, useState } from 'react'

import { Record } from '@/models/record'
import { AgGridApp, Container, FormControl, InputSelect } from '@/components'
import { RecordModal } from '@/pages/home/components/RecordModal'
import { OptionType } from '@/models/optionSelect'

import { columnsRecords } from '../data/columnsRecords'
import { dataSelectLimit, selectedLimitInitial } from '../data/dataSelectLimit'

interface Props {
  history?: Record[]
}

const GroupHistoryTabContent: FC<Props> = ({ history }) => {
  const [selectedRecord, setSelectedRecord] = useState<Record | undefined>(undefined)

  const columnsRecordsDefs = columnsRecords(setSelectedRecord)

  const [selectedHistoryLimitCount, setSelectedHistoryLimitCount] =
    useState<OptionType>(selectedLimitInitial)

  const handleChangeHistoryLimitCount = (option: OptionType) => {
    setSelectedHistoryLimitCount(option)
  }

  return (
    <Container className="grow-0 overflow-[unset]">
      <div className="flex flex-col gap-[20px] md:pr-[40px] w-full ">
        <div className="flex items-start justify-between">
          <span className="text-[25px] font-bold">Lastest Updates</span>
          <div className="w-[170px]">
            <FormControl label="Select a limit">
              <InputSelect
                listOptions={dataSelectLimit}
                valueOption={selectedHistoryLimitCount}
                handleChange={handleChangeHistoryLimitCount}
              />
            </FormControl>
          </div>
        </div>

        <div className="flex flex-col w-full gap-[20px]">
          <AgGridApp
            rowData={history}
            columnDefs={columnsRecordsDefs}
            isShowPagination={false}
            isShowFilter={false}
            suppressRowHoverHighlight
            suppressCellFocus
            paginationPageSize={20}
          />
        </div>
      </div>
      <RecordModal
        record={selectedRecord}
        handleCloseModal={() => {
          setSelectedRecord(undefined)
        }}
      />
    </Container>
  )
}

export { GroupHistoryTabContent }
