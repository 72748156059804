import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid'
import { EyeIcon, PencilIcon } from '@heroicons/react/24/outline'
import { format, parseISO } from 'date-fns'

import { Store } from '@/models/store'
import { Badge, ButtonApp } from '@/components'
import { labels } from '@/constants/labels'
import { OptionType } from '@/models/optionSelect'
import { getBadgeVariantByStatus, getVersion } from '@/utils'

type ColumnProps = { data: Store }

export default function Columns(
  handleEdit: (store: Store) => void,
  handleRedirectToInfoStore: (id: string) => void,
  groups: OptionType[],
) {
  const menuItems = [
    {
      label: 'View Details',
      icon: <EyeIcon className="w-4" />,
      primary: true,
      command: ({ id: storeId }: Store) => {
        handleRedirectToInfoStore(storeId)
      },
    },
    {
      label: 'Edit',
      icon: <PencilIcon className="w-4" />,
      primary: false,
      command: (store: Store) => {
        handleEdit(store)
      },
    },
  ]

  const getGroupName = (id: string) => {
    return groups.find((group: OptionType) => group.id === id)
  }

  return [
    {
      field: 'group',
      headerName: labels.group,
      minWidth: 150,
      cellRenderer: (props: ColumnProps) => {
        return <span>{getGroupName(props.data.groupId)?.label ?? ''}</span>
      },
    },
    {
      field: 'accountId',
      headerName: 'Account',
      minWidth: 200,
    },
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 200,
    },
    {
      field: 'name',
      headerName: 'Store Name',
      minWidth: 200,
    },
    {
      id: 'serviceVersion',
      headerName: 'Service Version',
      minWidth: 320,
      cellRenderer: (props: ColumnProps) => {
        return (
          <div className="flex gap-2 items-center">
            {props.data.lambdaAlgoliaSrcKey && (
              <div className="py-1 px-3 border rounded-2xl text-xs flex items-center gap-2 text-gray-500 cursor-pointer border-[#f5a66c]">
                <span>Algolia</span>
                <span className="text-black rounded-3xl py-1 px-2 bg-[#f5a66c]">
                  {getVersion(props.data.lambdaAlgoliaSrcKey)}
                </span>
              </div>
            )}
            {props.data.cognitoLambdaTriggerSrcKey && (
              <div className="py-1 px-3 border rounded-2xl text-xs flex items-center gap-2 text-gray-500 cursor-pointer border-[#f5a66c]">
                <span>Cognito</span>
                <span className="text-black rounded-3xl py-1 px-2 bg-[#f5a66c]">
                  {getVersion(props.data.cognitoLambdaTriggerSrcKey)}
                </span>
              </div>
            )}
          </div>
        )
      },
    },
    {
      field: 'created',
      headerName: 'Created at',
      minWidth: 150,
      cellRenderer: (props: ColumnProps) => (
        <div className="flex flex-col">
          <span className="text-gray-800 text-sm">
            {format(parseISO(props.data?.created ?? new Date().toISOString()), 'MM/dd/yyyy')}
          </span>
          <span className="text-gray-400 text-xs">
            {format(parseISO(props.data?.created ?? new Date().toISOString()), 'hh:mm:ss')}
          </span>
        </div>
      ),
    },
    {
      field: 'workflowStatus',
      headerName: 'Status',
      width: 150,
      flex: false,
      pinned: 'right',
      cellRenderer: (props: ColumnProps) => {
        const badgeVariant = getBadgeVariantByStatus(props.data.workflowStatus)

        return (
          <div className="flex items-center h-full">
            <Badge className="w-fit text-sm" {...badgeVariant}>
              {props.data.workflowStatus ?? 'unspecified'}
            </Badge>
          </div>
        )
      },
    },
    {
      id: 'actions',
      headerName: 'Actions',
      width: 100,
      flex: false,
      filter: false,
      pinned: 'right',
      cellRenderer: (props: ColumnProps) => (
        <div className="flex items-center justify-center h-full m-auto">
          <Menu>
            <MenuButton>
              <EllipsisVerticalIcon width={22} className="text-gray-600" />
            </MenuButton>
            <MenuItems anchor="bottom end" className="bg-white p-2 shadow-xl flex flex-col gap-2">
              {menuItems.map(({ icon, label, primary, command }) => (
                <MenuItem>
                  <ButtonApp
                    className="px-4 py-1.5 gap-2"
                    isPrimary={primary}
                    handleClick={() => command(props.data)}
                  >
                    {icon}
                    <span className="text-sm">{label}</span>
                  </ButtonApp>
                </MenuItem>
              ))}
            </MenuItems>
          </Menu>
        </div>
      ),
    },
  ]
}
