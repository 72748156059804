import { useState } from 'react'
import { ChevronRightIcon, PlusCircleIcon } from '@heroicons/react/24/outline'
import { IoExtensionPuzzleOutline } from 'react-icons/io5'

import { ButtonApp, Container, Spinner } from '@/components'
import { Group } from '@/models/group'

import { CreateAndEditGroupModal, GroupCard, BulkEditModal } from './components'
import { useGetGroups } from './hooks'

export const Groups = () => {
  const {
    handleOpenModal,
    handleCloseModal,
    isOpenModal,
    groups,
    isLoading,
    group,
    handleSubmitGroup,
    isLoadingGetAllGroups,
    handleSetGroup,
  } = useGetGroups()
  const [showBulkModal, setShowBulkModal] = useState(false)

  return (
    <>
      {(isLoading || isLoadingGetAllGroups) && <Spinner />}
      <Container
        header={
          <div className="flex xs:flex-col-reverse md:flex-row md:justify-between gap-[15px]">
            <p className="text-2xl font-bold">Groups</p>
            <div className="flex justify-end gap-4">
              <ButtonApp
                handleClick={() => {
                  setShowBulkModal(true)
                }}
              >
                <div className="flex items-center gap-[10px]">
                  <span>Bulk Update</span>
                  <ChevronRightIcon className="text-gray-600 h-4 w-4" />
                </div>
              </ButtonApp>
              <ButtonApp isPrimary handleClick={handleOpenModal}>
                <div className="flex items-center gap-[10px]">
                  <PlusCircleIcon className="text-white h-[24px] w-[24px]" />
                  <span>Create new Group</span>
                </div>
              </ButtonApp>
            </div>
          </div>
        }
      >
        {groups.length ? (
          <div className="w-full h-full grow overflow-y-auto grid grid-cols-[repeat(_auto-fill,minmax(450px,1fr)_)] 4xl:grid-cols-[repeat(_auto-fill,minmax(550px,1fr)_)] items-stretch gap-6 auto-rows-max">
            {groups.map((group, index) => (
              <GroupCard key={index} group={group} onEdit={handleSetGroup} />
            ))}
          </div>
        ) : (
          <div className="w-full h-full flex flex-col items-center justify-center py-12">
            <IoExtensionPuzzleOutline className="text-[2.5rem] text-gray-400" />
            <h3 className="mt-2 text-sm font-semibold text-gray-900">No groups</h3>
            <p className="mt-1 text-sm text-gray-500">Get started by creating a new group.</p>
          </div>
        )}
        {isOpenModal && (
          <CreateAndEditGroupModal
            isOpenModal={isOpenModal}
            handleCloseModal={handleCloseModal}
            group={group as Group}
            handleSubmitGroup={handleSubmitGroup}
          />
        )}
        {showBulkModal && (
          <BulkEditModal
            isOpenModal={showBulkModal}
            handleCloseModal={() => {
              setShowBulkModal(false)
            }}
          />
        )}
      </Container>
    </>
  )
}
