import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Store } from '@/models/store'
import { ApiRequestException, Status } from '@/models/apiResponse'
import { OptionType } from '@/models/optionSelect'
import { Group } from '@/models/group'
import { GroupService } from '@/services/group'
import { useNotify } from '@/hooks'

import Columns from '../data/columns'
import { useStores } from './useStores'

export const useGetStores = () => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [store, setStore] = useState<Store | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { stores, isLoading: isLoadingStores } = useStores()
  const [groups, setGroups] = useState<OptionType[]>([{ id: '', label: 'Select a group' }])
  const navigate = useNavigate()
  const { notifyError } = useNotify()

  const handleGetAllGroups = useCallback(async () => {
    setIsLoading(true)
    try {
      const response = await GroupService.getAllGroups()
      if (response.status === Status.success) {
        const data = response.data as Group[]
        const dataType = data.map((group: Group) => ({ id: group.id, label: group.name }))
        setGroups([...groups, ...dataType])
      }
    } catch (error: unknown) {
      const errorResponse = error as ApiRequestException
      notifyError(errorResponse?.error?.content ?? 'There is an error')
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    setIsLoading(true)
    Promise.all([handleGetAllGroups()]).finally(() => setIsLoading(false))
  }, [])

  const handleSetStore = (item: Store) => {
    setStore(item)
    handleOpenModal()
  }

  const handleRedirectToInfoStore = (id: string) => {
    navigate(`/stores/${id}`)
  }

  const columnsStoresDefs = Columns(handleSetStore, handleRedirectToInfoStore, groups)

  const handleOpenModal = () => setIsOpenModal(true)

  const handleCloseModal = () => {
    setIsOpenModal(false)
    setStore(null)
  }

  return {
    isOpenModal,
    store,
    columnsStoresDefs,
    handleCloseModal,
    handleOpenModal,
    stores,
    isLoading: isLoading || isLoadingStores,
    groups,
  }
}
