import { FormApp, FormControl, InputApp, ModalApp, TextAreaInput } from '@/components'
import { labels } from '@/constants/labels'

import { CreateAndEditGroupModalProps } from '../data/types'
import { useCreateAndEditGroupModal } from '../hooks'

export const CreateAndEditGroupModal = ({
  isOpenModal,
  handleCloseModal,
  group,
  handleSubmitGroup,
}: CreateAndEditGroupModalProps) => {
  const { register, handleSubmit, isDisabledButton, errors } = useCreateAndEditGroupModal({
    group,
  })

  return (
    <ModalApp
      isOpenModal={isOpenModal}
      handleCloseModal={handleCloseModal}
      title={`${group ? 'Edit' : 'Create'} Group`}
      maxHeight="xs:max-h-[820px] md:max-h-[700px]"
      maxWidth="xs:max-w-[340px] md:max-w-[600px]"
    >
      <FormApp
        handleCancel={handleCloseModal}
        handleSubmit={handleSubmit}
        onSubmit={handleSubmitGroup}
        isDisabledButton={isDisabledButton}
        textButton={group ? 'Save' : 'Create'}
      >
        <div className="flex flex-col gap-6">
          <FormControl label="Group Name" isRequired messageError={errors.name?.message}>
            <InputApp
              name="name"
              placeholder="Enter group's name"
              register={register}
              isError={!!errors.name?.message}
            />
          </FormControl>
          <FormControl label="Domain" isRequired messageError={errors.domain?.message}>
            <InputApp
              name="domain"
              placeholder="Enter group's domain"
              register={register}
              isError={!!errors.domain?.message}
            />
          </FormControl>
          <FormControl
            label={labels.description}
            isRequired
            messageError={errors.description?.message}
          >
            <TextAreaInput
              name="description"
              placeholder="Enter group's description"
              register={register}
              isError={!!errors.description?.message}
            />
          </FormControl>
        </div>
      </FormApp>
    </ModalApp>
  )
}
