import { useCallback } from 'react'
import { useQuery } from '@tanstack/react-query'

import { StoreService } from '@/services/store'
import { Store } from '@/models/store'

export const useStore = (storeId?: string) => {
  const queryKey = ['store', storeId]

  const getStore = useCallback(async () => {
    const response = await StoreService.getStoreDetail(storeId as string)
    return response.data as Store
  }, [storeId])

  const { data, isLoading, refetch } = useQuery({
    queryKey,
    queryFn: getStore,
    enabled: !!storeId,
    refetchInterval: 5000,
  })

  return { store: data, isLoading, refetch }
}
