import { RotatingLines } from 'react-loader-spinner'

export const Spinner = () => {
  return (
    <div className="absolute top-[50%] left-[50%] bg-white opacity-60 z-[2005] h-full translate-y-[-50%] translate-x-[-50%] w-full d-block">
      <div className="flex justify-center items-center h-[100%]">
        <RotatingLines
          visible
          width="40"
          strokeColor="#13ce66"
          strokeWidth="5"
          animationDuration="0.75"
          ariaLabel="rotating-lines-loading"
        />
      </div>
    </div>
  )
}
