import * as yup from 'yup'

export const addressSchema = yup.object().shape({
  address1: yup.string().required('Line 1 is required'),
  address2: yup.string().optional(),
  city: yup.string().required('City is required'),
  province_code: yup.string().required('State Code is required'),
  zip: yup.string().required('Zip is required'),
  country_code: yup.string().required('Country Code is required'),
})
