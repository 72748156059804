import { useParams } from 'react-router-dom'

import { Param } from '@/models/params'
import { useStores } from '@/pages/stores/hooks'
import { useAccount } from './useAccount'

export const useGetAccount = () => {
  const { groupId, accountId } = useParams<Param>()
  const { account, isLoading } = useAccount(groupId, accountId)
  const { stores, isLoading: isLoadingStores } = useStores(accountId)

  return {
    isLoading: isLoading || isLoadingStores,
    account,
    listStores: stores,
  }
}
