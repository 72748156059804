import { classNames } from '@/utils'

type InputAppProps = {
  name: string
  isDisabled?: boolean
  placeholder?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register?: any
  isError?: boolean
}
export const InputApp = ({
  name,
  isDisabled,
  placeholder,
  register,
  isError = false,
}: InputAppProps) => {
  return (
    <input
      className={classNames(
        'border-[1px] rounded-[6px] text-sm h-[40px] px-[20px] text-gray-400 border-gray-200 focus:border-gray-300 outline-none',
        { 'border-red': isError },
      )}
      {...register(name)}
      disabled={isDisabled}
      placeholder={placeholder}
      autoComplete="off"
    />
  )
}
