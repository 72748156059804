import { useMutation } from '@tanstack/react-query'

import { useNotify } from '@/hooks'
import { StoreBulkEditRequest } from '@/models/store'
import { StoreService } from '@/services/store'

import { BulkEditFormData } from '../data/types'

const useBulkUpdateStore = ({ onSuccess }: { onSuccess?(): void }) => {
  const { notifyError, notifySuccess } = useNotify()

  const bulkUpdate = async (data: BulkEditFormData) => {
    const requestBody: StoreBulkEditRequest = {
      ...data,
      ...(data?.cognitoConfig ? { cognitoConfig: JSON.parse(data?.cognitoConfig) } : {}),
    }
    const result = await StoreService.updateStoreBulk(requestBody)
    return result.data
  }

  const { mutate: update, isPending: isUpdating } = useMutation({
    mutationFn: bulkUpdate,
    onSuccess: () => {
      notifySuccess('Bulk update executed successfully')
      onSuccess?.()
    },
    onError: (error) => {
      notifyError(error.message ?? 'There was an error')
    },
  })

  return { update, isUpdating }
}

export { useBulkUpdateStore }
