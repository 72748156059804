import { useMatches, UIMatch, useLocation, Link } from 'react-router-dom'
import React from 'react'

import { classNames } from '@/utils'

interface CrumbHandle {
  crumb: () => React.ReactNode
}

function Breadcrumb() {
  const matches = useMatches() as UIMatch<unknown, CrumbHandle>[]
  const location = useLocation()

  const crumbs = matches.filter((match) => Boolean(match.handle?.crumb))

  if (!crumbs.length) return null

  return (
    <ol className="flex items-center gap-2 pb-6 h-11">
      {crumbs.map(({ pathname, handle }, index) => {
        const isActive = location.pathname === pathname

        return (
          <li key={index} className="flex gap-2 text-sm font-semibold text-gray-300">
            {index > 0 && <span>&#x2022;</span>}
            <Link
              to={pathname}
              className={classNames('cursor-pointer', { 'text-green-700': isActive })}
            >
              {handle!.crumb()}
            </Link>
          </li>
        )
      })}
    </ol>
  )
}

export { Breadcrumb }
