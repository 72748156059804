import { FC } from 'react'
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid'
import {
  BuildingStorefrontIcon,
  EyeIcon,
  PencilIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline'
import { format, parseISO } from 'date-fns'
import pluralize from 'pluralize'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { useNavigate } from 'react-router-dom'

import { Group } from '@/models/group'
import { classNames, getBadgeVariantByStatus } from '@/utils'
import { Badge, ButtonApp, ClipboardItem } from '@/components'

interface GroupCardProps {
  group: Group
  onEdit(_: Group): void
}

const GroupCard: FC<GroupCardProps> = ({ group, onEdit }) => {
  const navigate = useNavigate()
  const { name, description, workflowStatus, updated, outputs, totalAccounts, totalStores } = group
  const badgeVariant = getBadgeVariantByStatus(workflowStatus)
  const parsedOutput = outputs && JSON.parse(outputs)
  const graphQLUrl = parsedOutput?.ApiGateways?.ApiGatewayGraphqlGroup?.url
  const webhookUrl = parsedOutput?.ApiGateways?.apiGatewayWebHookGroup?.url
  const notificationWorker = parsedOutput?.SQS?.NotificationQueueUrl
  const appWorker = parsedOutput?.SQS?.AppQueueUrl
  const orderWorker = parsedOutput?.SQS?.OrderQueueUrl
  const idp = parsedOutput?.CognitoUserPoolGroup?.id
  const sns = parsedOutput?.SNS?.TopicArn

  const clipboardItems = [
    {
      label: 'GraphQL',
      value: graphQLUrl,
      version: getVersion(group.lambdaGraphqlSrcKey),
      color: 'blue',
    },
    {
      label: 'API',
      value: webhookUrl,
      version: getVersion(group.lambdaWebHookGroupSrcKey),
      color: 'blue',
    },
    {
      label: 'Notification Worker',
      value: notificationWorker,
      version: getVersion(group.pubsubs?.notification?.lambdaSrcKey ?? ''),
      color: 'green',
    },
    {
      label: 'App Worker',
      value: appWorker,
      version: getVersion(group.pubsubs?.app?.lambdaSrcKey ?? ''),
      color: 'green',
    },
    {
      label: 'Order Worker',
      value: orderWorker,
      version: getVersion(group.pubsubs?.order?.lambdaSrcKey ?? ''),
      color: 'green',
    },
    { label: 'IDP', value: idp },
    { label: 'SNS', value: sns },
  ].filter(({ value }) => !!value)

  const menuItems = [
    {
      label: 'View Details',
      icon: <EyeIcon className="w-4" />,
      primary: true,
      command: ({ id }: Group) => {
        navigate(`/groups/${id}`)
      },
    },
    {
      label: 'Edit',
      icon: <PencilIcon className="w-4" />,
      primary: false,
      command: onEdit,
      disabled: ['IN_PROGRESS', 'REQUESTED'].includes(group.workflowStatus),
    },
  ]

  return (
    <div
      className={classNames(
        'h-full bg-white p-3 border border-gray-100 border-l-4 border-l-gray-200 flex flex-col gap-3 rounded-[4px] cursor-pointer select-none',
        { 'border-l-green-200': workflowStatus === 'SUCCEEDED' },
        { 'border-l-yellow-200': workflowStatus === 'REQUESTED' },
        { 'border-l-blue-200': workflowStatus === 'IN_PROGRESS' },
        { 'border-l-red-200': workflowStatus === 'FAILED' },
      )}
      role="button"
      onDoubleClick={() => {
        navigate(`/groups/${group.id}`)
      }}
    >
      <div className="flex justify-between">
        <span className="font-medium text-gray-700 truncate max-w-[60%]" title={name}>
          {name}
        </span>
        <div className="flex gap-2">
          <Badge {...badgeVariant}>{workflowStatus}</Badge>
          <Menu>
            <MenuButton>
              <EllipsisVerticalIcon width={22} className="text-gray-600" />
            </MenuButton>
            <MenuItems anchor="left start" className="bg-white p-2 shadow-xl flex flex-col gap-2">
              {menuItems.map(({ icon, label, primary, command, disabled }) => (
                <MenuItem>
                  <ButtonApp
                    className="px-4 py-1.5 gap-2"
                    isPrimary={primary}
                    handleClick={(e) => {
                      e.stopPropagation()
                      command(group)
                    }}
                    isDisabled={disabled}
                  >
                    {icon}
                    <span className="text-sm">{label}</span>
                  </ButtonApp>
                </MenuItem>
              ))}
            </MenuItems>
          </Menu>
        </div>
      </div>
      <div className="flex gap-6">
        <div className="flex items-center gap-2 text-gray-500 font-medium">
          <UserCircleIcon width={20} />
          <span className="text-xs">
            {totalAccounts} {pluralize('ACCOUNT', totalAccounts)}
          </span>
        </div>
        <div className="flex items-center gap-2 text-gray-500 font-medium">
          <BuildingStorefrontIcon width={20} />
          <span className="text-xs">
            {totalStores} {pluralize('STORE', totalStores)}
          </span>
        </div>
      </div>
      <div className="flex gap-2 flex-wrap">
        {clipboardItems.map(({ label, value, version, color }, index) => (
          <ClipboardItem key={index} headless value={value}>
            {({ onClick, clipboardIcon }) => (
              <button
                className={classNames(
                  'py-1 px-3 border rounded-2xl text-xs flex items-center gap-2 text-gray-400 cursor-pointer',
                  { 'border-blue-200': color === 'blue' },
                  { 'border-[#99E0BA]': color === 'green' },
                )}
                onClick={onClick}
              >
                <span>{label}</span>
                {version && (
                  <span
                    className={classNames('text-white rounded-3xl py-1 px-2', {
                      'bg-[#2E59A3]': color === 'blue',
                      'bg-[#288F58]': color === 'green',
                    })}
                  >
                    {version}
                  </span>
                )}
                {clipboardIcon}
              </button>
            )}
          </ClipboardItem>
        ))}
      </div>
      <p className="text-gray-400 text-xs min-h-12">{description}</p>
      <p className="text-xs">
        <span className="text-gray-500 font-medium">Last Updated: &nbsp;</span>
        <span className="text-gray-400">{format(parseISO(updated), 'MMMM do, yyyy')}</span>
      </p>
    </div>
  )
}

const getVersion = (text: string) => text.match(/-([\w\d.]+)\.zip/)?.[1]

export { GroupCard }
