import { Address } from '@/types'

const getBadgeVariantByStatus = (status?: string) =>
  status === 'SUCCEEDED' || status === 'ACTIVE'
    ? { isSuccess: true as const }
    : status === 'REQUESTED'
      ? { isWarning: true as const }
      : status === 'IN_PROGRESS'
        ? { isInfo: true as const }
        : status === 'FAILED'
          ? { isDanger: true as const }
          : {}

const toAddressString = (address?: Address): string => {
  if (!address) return 'Unspecified'

  const { address1, address2, city, zip, province, province_code, country, country_code } = address

  let addressString = `${address1}, `
  if (address2) {
    addressString += `${address2}, `
  }
  addressString += `${city}, `

  if (province && province_code) {
    addressString += `${province} (${province_code}), `
  } else if (province) {
    addressString += `${province}, `
  } else if (province_code) {
    addressString += `${province_code}, `
  }

  if (country) {
    addressString += `${country} (${country_code}) `
  } else {
    addressString += `${country_code} `
  }

  addressString += `- ${zip}`

  return addressString
}

const getVersion = (text?: string) => text?.match(/-([\w\d.]+)\.zip/)?.[1]

export { getBadgeVariantByStatus, toAddressString, getVersion }
