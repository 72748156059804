import { IoEyeOutline } from 'react-icons/io5'

import { labels } from '@/constants/labels'
import { ButtonApp } from '@/components'
import { Group } from '@/models/group'

type ColumnProps = { data: Group }

export default function ColumnsGroups(navigateToGroup: (storeId: string) => void) {
  return [
    {
      field: 'id',
      headerName: labels.id,
      filter: false,
    },
    { field: 'name', headerName: labels.name, filter: false },
    {
      headerName: 'Action',
      cellRenderer: (props: ColumnProps) => (
        <div className="h-full flex items-center">
          <ButtonApp
            className="px-4 py-1.5 gap-2 h-8"
            handleClick={(e) => {
              e.stopPropagation()
              navigateToGroup(props.data.id)
            }}
          >
            <IoEyeOutline className="text-xl text-gray-400" />
            <span className="text-sm">View</span>
          </ButtonApp>
        </div>
      ),
      filter: false,
    },
  ]
}
