import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Param } from '@/models/params'
import { ApiRequestException, Status } from '@/models/apiResponse'
import { Record } from '@/models/record'
import { Store } from '@/models/store'
import { GroupService } from '@/services/group'
import { StoreService } from '@/services/store'
import { useNotify } from '@/hooks'

import Columns from '../data/columns.tsx'
import { useGroupAccounts } from './useGroupAccounts.ts'
import { useGroup } from './useGroup.ts'

export const useGetGroup = () => {
  const [stores, setStores] = useState<Store[]>([])
  const [groupHistory, setGroupHistory] = useState<Record[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { groupId } = useParams<Param>()
  const { group, isLoading: isLoadingGroup } = useGroup(groupId)
  const { accounts: groupAccounts, isLoading: isLoadingAccounts } = useGroupAccounts(groupId)
  const { notifyError } = useNotify()

  const handleGetAllStores = useCallback(async () => {
    try {
      const response = await StoreService.getAllStores()
      if (response.status === Status.success) {
        const data = response.data as Store[]
        setStores(data)
      }
    } catch (error: unknown) {
      const errorResponse = error as ApiRequestException
      notifyError(errorResponse?.error?.content ?? 'There is an error')
    }
  }, [])

  const handleGetGroupHistory = useCallback(async () => {
    try {
      const response = await GroupService.getGroupHistory(groupId as string)
      if (response.status === Status.success) {
        const data = response.data as Record[]
        setGroupHistory(data)
      }
    } catch (error: unknown) {
      const errorResponse = error as ApiRequestException
      notifyError(errorResponse?.error?.content ?? 'There is an error')
    }
  }, [groupId])

  useEffect(() => {
    setIsLoading(true)
    Promise.all([handleGetGroupHistory(), handleGetAllStores()]).finally(() => setIsLoading(false))
  }, [])

  const columnsStoresDefs = Columns(group?.name as string)

  const listStores = stores.filter((store: Store) => store.groupId === groupId)

  return {
    isLoading: isLoading || isLoadingAccounts || isLoadingGroup,
    group,
    columnsStoresDefs,
    groupHistory,
    listStores,
    groupAccounts: groupAccounts ?? [],
  }
}
