import { IoEyeOutline } from 'react-icons/io5'

import { Store } from '@/models/store'
import { labels } from '@/constants/labels'
import { ButtonApp } from '@/components'

type ColumnProps = { data: Store }

export default function ColumnsStores(
  handleGetGroupName: (groupId: string) => void,
  navigateToStore: (storeId: string) => void,
) {
  return [
    {
      field: 'name',
      headerName: labels.name,
      filter: false,
    },
    {
      field: 'group',
      headerName: labels.group,
      cellRenderer: (props: ColumnProps) => handleGetGroupName(props.data.groupId),
      filter: false,
    },
    {
      headerName: 'Action',
      cellRenderer: (props: ColumnProps) => (
        <div className="h-full flex items-center">
          <ButtonApp
            className="px-4 py-1.5 gap-2 h-8"
            handleClick={(e) => {
              e.stopPropagation()
              navigateToStore(props.data.id)
            }}
          >
            <IoEyeOutline className="text-xl text-gray-400" />
            <span className="text-sm">View</span>
          </ButtonApp>
        </div>
      ),
      filter: false,
    },
  ]
}
