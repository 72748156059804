import { useReducer } from 'react'
import { useParams } from 'react-router-dom'
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import { PlusCircleIcon } from '@heroicons/react/24/outline'

import { ButtonApp, Container, Spinner } from '@/components'
import { Store } from '@/models/store'
import { Param } from '@/models/params'

import { useGetAccount } from './hooks'
import { AccountDetailsTabContent, AccountRawTabContent } from './components'
import { CreateOrEditStoreModal } from '../stores/components'
import { useGroup } from '../showGroup/hooks'

const tabListHeaders = ['Details', 'Raw']

export const ShowAccount = () => {
  const { account, listStores, isLoading } = useGetAccount()
  const { groupId } = useParams<Param>()
  const { group, isLoading: isLoadingGroup } = useGroup(groupId)
  const { showModal, storeInitialValues, storeToEdit, createStore, editStore, closeModal } =
    useReducerState()

  return (
    <div className="flex flex-col grow overflow-hidden">
      {(isLoading || isLoadingGroup) && <Spinner />}
      <Container
        header={
          <div className="flex grow overflow-hidden xs:flex-col-reverse md:flex-row md:justify-between gap-[15px]">
            <p className="text-2xl font-bold">{account?.company}</p>
            <div className="flex justify-end">
              <ButtonApp
                isPrimary
                handleClick={() => {
                  createStore({
                    accountId: account?.id,
                    groupId: group?.id,
                  })
                }}
              >
                <div className="flex items-center gap-[10px]">
                  <PlusCircleIcon className="text-white h-[24px] w-[24px]" />
                  <span>Create Store</span>
                </div>
              </ButtonApp>
            </div>
          </div>
        }
      >
        <TabGroup className="grow flex flex-col gap-6 max-w-full">
          <TabList className="flex gap-2 items-baseline border-b border-gray-100 px-8">
            {tabListHeaders.map((header, index) => (
              <Tab
                key={index}
                className="-mb-[1px] focus-visible:outline-none py-2 px-4 text-gray-500 text-sm rounded-[4px] rounded-b-none border border-gray-100 bg-[#F5F7FA] data-[selected]:bg-white data-[selected]:border-b-white"
              >
                {header}
              </Tab>
            ))}
          </TabList>
          <TabPanels className="grow overflow-auto">
            <TabPanel>
              <AccountDetailsTabContent
                account={account}
                stores={listStores}
                onStoreSelected={editStore}
                isLoading={isLoading}
              />
            </TabPanel>
            <TabPanel>
              <AccountRawTabContent account={account} />
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </Container>
      <CreateOrEditStoreModal
        isOpenModal={showModal}
        handleCloseModal={closeModal}
        store={storeToEdit || storeInitialValues}
        groupSelectionLocked
        accountSelectionLocked
      />
    </div>
  )
}

type State = {
  showModal: boolean
  storeInitialValues?: Partial<Store>
  storeToEdit?: Store
}

const initialState: State = {
  showModal: false,
  storeToEdit: undefined,
}

const reducer = (
  initialState: State,
  action:
    | { type: 'createStore'; payload?: { store?: Partial<Store> } }
    | { type: 'editStore'; payload?: { store?: Store } }
    | { type: 'closeModal' },
): State => {
  switch (action.type) {
    case 'createStore':
      return { ...initialState, storeInitialValues: action.payload?.store, showModal: true }
    case 'editStore':
      return { ...initialState, storeToEdit: action?.payload?.store, showModal: true }
    case 'closeModal':
      return { storeToEdit: undefined, showModal: false }
    default:
      return initialState
  }
}

const useReducerState = () => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const createStore = (store: Partial<Store>) => {
    dispatch({ type: 'createStore', payload: { store } })
  }

  const editStore = (store: Store) => {
    dispatch({ type: 'editStore', payload: { store } })
  }

  const closeModal = () => {
    dispatch({ type: 'closeModal' })
  }

  return { ...state, createStore, editStore, closeModal }
}
