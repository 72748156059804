import { useCallback } from 'react'
import { useQuery } from '@tanstack/react-query'

import { GroupService } from '@/services/group'
import { Group } from '@/models/group'

export const useGetAllGroups = () => {
  const queryKey = ['all-groups']

  const getAllGroups = useCallback(async () => {
    const response = await GroupService.getAllGroups()
    return response.data as Group[]
  }, [])

  const { data, isLoading, refetch } = useQuery({
    queryKey,
    queryFn: getAllGroups,
    refetchInterval: 5000,
  })

  return { groups: data ?? [], isLoading, refetch }
}
