import { ApiResponse } from '@/models/apiResponse'

export function makeErrorResponse<T>(error: any): ApiResponse<T> {
  return {
    error: {
      status: error.response?.status ?? 500,
      statusText: error.response?.statusText ?? '',
      content: error.response?.data?.message ?? null,
    },
  }
}