import axios, { AxiosRequestConfig } from 'axios'
import promise from 'promise'
import { fetchAuthSession } from '@aws-amplify/auth'

import { ApiResponse } from '@/models/apiResponse'
import { RequestProps } from './types'
import { makeErrorResponse, makeResponse } from './helpers'

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_CORE_URL_BASE, // API_URL,
})

axiosInstance.interceptors.request.use(
  async (response: any) => {
    const session: any = await fetchAuthSession()
    const token: string = session.tokens.accessToken.toString()
    if (token) {
      response.headers.Authorization = `Bearer ${token}`
    }
    return response
  },
  (error) => {
    return promise.reject(error)
  },
)

export const sendRequest = async <T>(props: RequestProps): Promise<ApiResponse<T>> => {
  const headers: any = {
    'Content-Type': 'application/json',
  }
  const config: AxiosRequestConfig = {
    maxBodyLength: Infinity,
    headers,
  }
  let response = null
  try {
    if (props.get) response = await axiosInstance.get(props.get.path, config)
    if (props.post) response = await axiosInstance.post(props.post.path, props.post.body, config)
    if (props.put) response = await axiosInstance.put(props.put.path, props.put.body, config)
  } catch (error) {
    console.log('error in sendrequets', error)
    throw makeErrorResponse<T>(error)
  }
  return makeResponse<T>(response)
}