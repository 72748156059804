import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { CgSpinner } from 'react-icons/cg'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  ButtonApp,
  ConfirmDialog,
  FormApp,
  FormControl,
  SelectField,
  TextAreaInput,
} from '@/components'
import { Store } from '@/models/store'
import { getVersion } from '@/utils'

import { backendResourcesSchema } from '../data/schema'
import { useArtifacts } from '../../groups/hooks'
import { BackendResourcesFormData } from '../data/types'
import { useUpdateStore } from '../../stores/hooks'

const BackendResourcesForm: FC<{ store?: Store }> = ({ store }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<BackendResourcesFormData>({
    defaultValues: getBackendResourcesValuesFromStore(store),
    values: getBackendResourcesValuesFromStore(store),
    mode: 'onChange',
    resolver: yupResolver(backendResourcesSchema),
    disabled: !store,
  })
  const [dataToUpdate, setDataToUpdate] = useState<BackendResourcesFormData | undefined>(undefined)
  const { updateStore, isUpdating } = useUpdateStore({
    onSuccess: () => setDataToUpdate(undefined),
  })

  const { artifacts, isLoading: isLoadingArtifacts } = useArtifacts()

  const lambdaAlgoliaSrcKey =
    artifacts?.['e-com-dynamodbstream-srv']?.map((artifact) => ({
      label: artifact,
      value: `e-com-dynamodbstream-srv/${artifact}`,
    })) ?? []
  const cognitoLambdaTriggerSrcKey =
    artifacts?.['e-com-cognito-worker']?.map((artifact) => ({
      label: artifact,
      value: `e-com-cognito-worker/${artifact}`,
    })) ?? []

  const renderItemTemplate = (value: string) => (
    <span className="text-black rounded-full px-2 text-xs w-fit h-6 flex items-center bg-[#f5a66c]">
      {getVersion(value)}
    </span>
  )

  return (
    <>
      <FormApp
        handleCancel={() => {}}
        handleSubmit={handleSubmit}
        onSubmit={(data: BackendResourcesFormData) => {
          setDataToUpdate(data)
        }}
        defaultButtonsHidden
        outsideModal
      >
        <div className="flex flex-col gap-6">
          <div className="flex items-center justify-between">
            <span className="text-gray-800 font-medium">Backend Resources</span>
            <ButtonApp
              type="submit"
              width="md:min-w-[160px] w-fit"
              className="h-12 flex items-center gap-2 text-sm"
              isDisabled={['REQUESTED', 'IN_PROGRESS'].includes(store?.workflowStatus ?? '')}
            >
              {isUpdating && <CgSpinner className="animate-spin text-lg" />}
              <span>Deploy changes</span>
            </ButtonApp>
          </div>
          <div className="grid grid-cols-2 gap-6">
            <fieldset className="rounded-lg border border-[#F0F2F7] p-6 flex flex-col gap-6 self-start">
              <label>Backend compute</label>
              <div className="grid grid-cols-2 gap-6">
                <FormControl label="Algolia">
                  <SelectField
                    name="lambdaAlgoliaSrcKey"
                    register={register}
                    options={lambdaAlgoliaSrcKey}
                    placeholder="Select version"
                    isLoading={isLoadingArtifacts}
                    control={control}
                    valueTemplate={renderItemTemplate}
                    itemTemplate={renderItemTemplate}
                    optionClassName="data-[focus]:bg-yellow-50 data-[selected]:bg-yellow-50 hover:bg-yellow-50"
                    checkIconClassName="text-yellow-100 group-data-[selected]:text-[#f5a66c]"
                  />
                </FormControl>
                <FormControl label="Cognito">
                  <SelectField
                    name="cognitoLambdaTriggerSrcKey"
                    register={register}
                    options={cognitoLambdaTriggerSrcKey}
                    placeholder="Select version"
                    isLoading={isLoadingArtifacts}
                    control={control}
                    valueTemplate={renderItemTemplate}
                    itemTemplate={renderItemTemplate}
                    optionClassName="data-[focus]:bg-yellow-50 data-[selected]:bg-yellow-50 hover:bg-yellow-50"
                    checkIconClassName="text-yellow-100 group-data-[selected]:text-[#f5a66c]"
                  />
                </FormControl>
              </div>
            </fieldset>
            <fieldset className="rounded-lg border border-[#F0F2F7] p-6 flex flex-col gap-6 self-start">
              <label>IDP</label>
              <FormControl messageError={errors.cognitoConfig?.message}>
                <TextAreaInput
                  rows={7}
                  name="cognitoConfig"
                  placeholder="Enter cognito config"
                  register={register}
                  isError={!!errors.cognitoConfig?.message}
                />
              </FormControl>
            </fieldset>
          </div>
        </div>
      </FormApp>
      <ConfirmDialog
        visible={!!dataToUpdate}
        confirmText="Are you sure yo want to deploy these changes?"
        onHide={() => setDataToUpdate(undefined)}
        onConfirm={() => updateStore(sanitizeFormData(dataToUpdate!))}
        isLoading={isUpdating}
      />
    </>
  )
}

const getBackendResourcesValuesFromStore = (store?: Store): BackendResourcesFormData | undefined =>
  store
    ? {
        ...store,
        cognitoConfig: JSON.stringify(store.cognitoConfig, null, 2),
      }
    : undefined

const sanitizeFormData = (data: BackendResourcesFormData): Store => {
  const store: Store = {
    ...data,
    cognitoConfig: data?.cognitoConfig && JSON.parse(data?.cognitoConfig),
  }
  return store
}

export { BackendResourcesForm }
