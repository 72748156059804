import { classNames } from '@/utils'
import { MouseEventHandler, ReactNode } from 'react'

export type ButtonType = 'button' | 'submit' | 'reset'

type ButtonAppProps = {
  children: ReactNode
  isPrimary?: boolean
  width?: string
  handleClick?: MouseEventHandler<HTMLButtonElement>
  type?: ButtonType
  isDisabled?: boolean
  className?: string
}
export const ButtonApp = ({
  children,
  type,
  handleClick,
  isDisabled,
  isPrimary,
  className,
  width,
}: ButtonAppProps) => {
  const getBackground = () => {
    if (isPrimary && !isDisabled) {
      return 'bg-green-500 text-white cursor-pointer'
    } else if (isDisabled) {
      return 'opacity-40 bg-gray-100 cursor-default'
    } else {
      return 'bg-white border-[1px] border-gray-200 text-black cursor-pointer'
    }
  }

  return (
    <button
      className={classNames(
        `flex items-center justify-center w-auto rounded-[4px] h-[40px] px-[20px] py-3 ${getBackground()} ${width}`,
        className,
      )}
      onClick={handleClick}
      type={type ?? 'button'}
      disabled={isDisabled}
    >
      {children}
    </button>
  )
}
