import * as yup from 'yup'

export const schema = yup.object({
  name: yup.string().required('Name is required'),
  description: yup.string().required('Description is required'),
  groupId: yup.string().required('Group is required'),
  accountId: yup.string().required('Account is required'),
})

const cognitoCustomAttributeSchema = yup.object({
  name: yup.string().required('Custom attribute name is required'),
  mutable: yup.boolean().required('Mutable is required'),
  minLen: yup.number().optional(),
  maxLen: yup.number().optional(),
})

const cognitoRoleSchema = yup.object({
  roleName: yup.string().required('Role name is required'),
  description: yup.string().optional(),
})

const cognitoConfigSchema = yup.object({
  customAttributes: yup.array().of(cognitoCustomAttributeSchema).optional().default([]),
  defaultAttributes: yup
    .array()
    .of(yup.string().required('Default attribute must be a string'))
    .optional()
    .default([]),
  roles: yup.array().of(cognitoRoleSchema).optional().default([]),
})

export const bulkSchema = yup.object({
  lambdaAlgoliaSrcKey: yup.string().optional(),
  cognitoLambdaTriggerSrcKey: yup.string().optional(),
  cognitoConfig: yup
    .string()
    .test('is-valid-cognito-config', 'Must be a valid cognito configuration', (value) => {
      if (!value) return true
      try {
        const parsedValue = JSON.parse(value)
        return cognitoConfigSchema.isValidSync(parsedValue)
      } catch (error) {
        return false
      }
    }),
})
