import { FormApp, FormControl, InputApp, ModalApp, SelectField, TextAreaInput } from '@/components'
import { useGetAllGroups } from '@/hooks'

import { StoresModalProps, FormData } from '../../data/types'
import { useCreateOrEditStoreModal, useCreateStore, useUpdateStore } from '../../hooks'
import { useGroupAccounts } from '../../../showGroup/hooks/useGroupAccounts'

export const CreateOrEditStoreModal = ({
  isOpenModal,
  handleCloseModal,
  store,
  accountSelectionLocked,
  groupSelectionLocked,
}: StoresModalProps) => {
  const { register, handleSubmit, errors, watch, control } = useCreateOrEditStoreModal({
    store,
  })
  const { groups, isLoading: isLoadingGroups } = useGetAllGroups()
  const selectedGroupId = watch().groupId
  const { accounts, isLoading: isLoadingAccounts } = useGroupAccounts(selectedGroupId)
  const groupOptions = groups.map(({ id, name }) => ({ label: name, value: id }))
  const accountOptions = accounts.map(({ id, company }) => ({ label: company, value: id }))

  const { createStore, isCreating } = useCreateStore({ onSuccess: handleCloseModal })
  const { updateStore, isUpdating } = useUpdateStore({ onSuccess: handleCloseModal })

  const handleSubmitStore = async (data: FormData) =>
    data.id ? updateStore({ ...data, id: data.id }) : createStore(data)

  return (
    <ModalApp
      isOpenModal={isOpenModal}
      handleCloseModal={handleCloseModal}
      title={`${store?.id ? 'Edit' : 'Create'} Store`}
      maxHeight="xs:max-h-[820px] md:max-h-[800px]"
      maxWidth="xs:max-w-[340px] md:max-w-[600px]"
    >
      <FormApp
        handleCancel={handleCloseModal}
        handleSubmit={handleSubmit}
        onSubmit={handleSubmitStore}
        textButton={store?.id ? 'Save' : 'Create'}
        isSubmitting={isCreating || isUpdating}
      >
        <div className="grid grid-cols-2 gap-6">
          <FormControl
            label="Store Name"
            isRequired
            messageError={errors.name?.message}
            className="col-span-2"
          >
            <InputApp
              name="name"
              placeholder="Store name"
              register={register}
              isError={!!errors.name?.message}
            />
          </FormControl>
          <FormControl label="Group" messageError={errors.groupId?.message} isRequired>
            <SelectField
              name="groupId"
              register={register}
              options={groupOptions}
              placeholder="Select group"
              isLoading={isLoadingGroups}
              isDisabled={groupSelectionLocked}
              isError={!!errors.groupId?.message}
              control={control}
            />
          </FormControl>
          <FormControl label="Account" messageError={errors.accountId?.message} isRequired>
            <SelectField
              name="accountId"
              register={register}
              options={accountOptions}
              placeholder={selectedGroupId ? 'Select account' : 'Please select a group first'}
              isLoading={isLoadingAccounts}
              isDisabled={!selectedGroupId || accountSelectionLocked}
              isError={!!errors.accountId?.message}
              control={control}
            />
          </FormControl>
          <FormControl
            label="Description"
            isRequired
            messageError={errors.description?.message}
            className="col-span-2"
          >
            <TextAreaInput
              name="description"
              placeholder="Store description"
              register={register}
              isError={!!errors.description?.message}
            />
          </FormControl>
        </div>
      </FormApp>
    </ModalApp>
  )
}
