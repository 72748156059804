import { useMutation, useQueryClient } from '@tanstack/react-query'

import { GroupService } from '@/services/group'
import { useNotify } from '@/hooks'
import { CreateAccountRequest } from '@/models/account'

const useUpdateGroupAccount = ({ groupId, onSuccess }: { groupId: string; onSuccess?(): void }) => {
  const { notifyError, notifySuccess } = useNotify()
  const queryClient = useQueryClient()

  const update = async (data: CreateAccountRequest) => {
    const result = await GroupService.updateGroupAccount(groupId, data)
    return result.data
  }

  const { mutate: updateAccount, isPending: isUpdating } = useMutation({
    mutationFn: update,
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: ['group-accounts', groupId] })
      await queryClient.refetchQueries({ queryKey: ['group', groupId] })
      notifySuccess('Account updated successfully')
      onSuccess?.()
    },
    onError: (error) => {
      notifyError(error.message ?? 'There was an error')
    },
  })

  return { updateAccount, isUpdating }
}

export { useUpdateGroupAccount }
