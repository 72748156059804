import { NavLink } from 'react-router-dom'
import { ChevronRightIcon } from '@heroicons/react/24/solid'

import { classNames } from '@/utils'
import logoUrl from '@/assets/gapcommerce_logo.svg'

import { links } from './data'

export const SideBar = () => (
  <section className="bg-dark-gray w-1/6 min-w-56 max-w-72 items-center justify-center shadow-xl z-[1500] min-h-[100vh] h-full duration-1000 flex-shrink-0">
    <div className="flex flex-col gap-8 h-full w-full">
      <div className="w-full py-6 px-8 flex justify-between">
        <img className="h-8 w-auto" src={logoUrl} alt="Gap Store Manager" />
      </div>
      <div className="flex flex-col gap-[12px]">
        {links.map((link) => (
          <NavLink
            to={link.route}
            key={link.id}
            className="h-[43px] cursor-pointer text-black flex items-center"
          >
            {({ isActive }) => (
              <div
                className={classNames('w-full h-full text-light-gray py-4 px-8', {
                  'bg-dark text-white': isActive,
                })}
              >
                <div
                  className={`flex items-center justify-between w-full h-full transition ease-in-out delay-80`}
                >
                  <div className="flex gap-2">
                    {link.icon(isActive)}
                    {link.name}
                  </div>
                  <ChevronRightIcon
                    className={classNames('h-6 text-gray', { 'text-saturine': isActive })}
                  />
                </div>
              </div>
            )}
          </NavLink>
        ))}
      </div>
    </div>
  </section>
)
