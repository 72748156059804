import { NavigateFunction } from 'react-router-dom'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { EyeIcon, PencilIcon } from '@heroicons/react/24/outline'
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid'

import { Badge, ButtonApp } from '@/components'
import { Account } from '@/models/account'
import { getBadgeVariantByStatus, toAddressString } from '@/utils'
import { format, parseISO } from 'date-fns'

type ColumnProps = { data: Account }

export const columnsAccounts = (
  groupId: string,
  navigate: NavigateFunction,
  onSelect: (_: Account) => void,
) => {
  const menuItems = [
    {
      label: 'View Details',
      icon: <EyeIcon className="w-4" />,
      primary: true,
      command: ({ id: accountId }: Account) => {
        navigate(`/groups/${groupId}/${accountId}`)
      },
    },
    { label: 'Edit', icon: <PencilIcon className="w-4" />, primary: false, command: onSelect },
    // { label: 'Delete', icon: <TrashIcon className="w-4" />, primary: false, command: () => {} },
  ]

  return [
    { field: 'id', headerName: 'ID' },
    { field: 'company', headerName: 'Company' },
    {
      field: 'created_at',
      headerName: 'Created at',
      cellRenderer: (props: ColumnProps) => (
        <div className="flex flex-col gap-1">
          <span className="text-gray-800 text-sm">
            {format(parseISO(props.data?.created_at ?? new Date().toISOString()), 'MM/dd/yyyy')}
          </span>
          <span className="text-gray-400 text-xs">
            {format(parseISO(props.data?.created_at ?? new Date().toISOString()), 'hh:mm:ss')}
          </span>
        </div>
      ),
    },
    { field: 'first_name', headerName: 'First Name' },
    { field: 'last_name', headerName: 'Last Name' },
    { field: 'phone', headerName: 'Phone' },
    {
      field: 'address',
      headerName: 'Address',
      cellRenderer: (props: ColumnProps) => (
        <div className="flex flex-col flex-wrap">{toAddressString(props.data.address)}</div>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      cellRenderer: (props: ColumnProps) => {
        const badgeVariant = getBadgeVariantByStatus(props.data.status)

        return (
          <div className="flex items-center h-full">
            <Badge className="w-fit text-sm" {...badgeVariant}>
              {props.data.status ?? 'unspecified'}
            </Badge>
          </div>
        )
      },
    },
    {
      headerName: 'Actions',
      cellRenderer: (props: ColumnProps) => {
        return (
          <div className="flex items-center h-full">
            <Menu>
              <MenuButton>
                <EllipsisVerticalIcon width={22} className="text-gray-600" />
              </MenuButton>
              <MenuItems anchor="bottom end" className="bg-white p-2 shadow-xl flex flex-col gap-2">
                {menuItems.map(({ icon, label, primary, command }) => (
                  <MenuItem>
                    <ButtonApp
                      className="px-4 py-1.5 gap-2"
                      isPrimary={primary}
                      handleClick={() => command(props.data)}
                    >
                      {icon}
                      <span className="text-sm">{label}</span>
                    </ButtonApp>
                  </MenuItem>
                ))}
              </MenuItems>
            </Menu>
          </div>
        )
      },
    },
  ]
}
