import { useCallback } from 'react'
import { useQuery } from '@tanstack/react-query'
import { compareDesc } from 'date-fns'

import { StoreService } from '@/services/store'
import { Store } from '@/models/store'

export const useStores = (accountId?: string) => {
  const queryKey = ['stores', accountId]

  const getStores = useCallback(async () => {
    const response = await StoreService.getAllStores()
    const stores = response.data as Store[]
    const sortedStores = stores.sort(
      ({ updated: firstStoreUpdated }, { updated: secondStoreUpdated }) =>
        compareDesc(firstStoreUpdated, secondStoreUpdated),
    )
    const filteredStoresByAccount = accountId
      ? sortedStores?.filter((store: Store) => store.accountId === accountId)
      : sortedStores

    return filteredStoresByAccount ?? []
  }, [])

  const { data, isLoading, refetch } = useQuery({
    queryKey,
    queryFn: getStores,
    refetchInterval: 5000,
  })

  return { stores: data ?? [], isLoading, refetch }
}
