import { FC } from 'react'
import { format, parseISO } from 'date-fns'

import { Badge, InfoElement } from '@/components'
import { getBadgeVariantByStatus } from '@/utils'
import { Store } from '@/models/store'

import { BackendResourcesForm } from './BackendResourcesForm'

interface Props {
  store?: Store
  accountName?: string
  groupName?: string
}

const StoreDetailsTabContent: FC<Props> = ({ store, accountName, groupName }) => {
  const badgeVariant = getBadgeVariantByStatus(store?.workflowStatus)

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-3">
        <Badge className="w-fit text-sm" title="Status" {...badgeVariant}>
          {store?.workflowStatus}
        </Badge>
        <div className="flex gap-2 text-gray-700 font-bold text-lg">
          <span title="Workflow ID">{store?.workflowId}</span>
          <span>-</span>
          <span>{`Last Update (${format(
            parseISO(store?.updated ?? new Date().toISOString()),
            'MMM do, yyyy - hh:mm:ss',
          )})`}</span>
        </div>
      </div>
      <div className="p-6 rounded-lg border border-gray-100 flex divide-x-2 divide-gray-100 overflow-x-auto">
        <InfoElement className="grow shrink-0 pr-8" label="Name" value={store?.name} />
        <InfoElement className="grow shrink-0 pr-8 pl-8" label="Id" value={store?.id} />
        <InfoElement className="grow shrink-0 pr-8 pl-8" label="Account" value={accountName} />
        <InfoElement className="grow shrink-0 pr-8 pl-8" label="Group" value={groupName} />
        <InfoElement
          className="grow shrink-0 pr-8 pl-8"
          label="Created At"
          value={format(
            parseISO(store?.created ?? new Date().toISOString()),
            'MM/dd/yyyy - hh:mm:ss',
          )}
        />
      </div>
      <BackendResourcesForm store={store} />
    </div>
  )
}

export { StoreDetailsTabContent }
