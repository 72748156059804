import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { FormData, UseCreateAndEditGroupModalProps } from '../data/types'
import { schema } from '../data/schema'

export const useCreateAndEditGroupModal = ({ group }: UseCreateAndEditGroupModalProps) => {
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      name: '',
      domain: '',
      description: '',
    },
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (group) {
      setValue('name', group.name)
      setValue('domain', group.domain ?? '')
      setValue('description', group.description)
    }
  }, [group])

  const isDisabledButton =
    !watch().name || !watch().domain || !watch().description || !!Object.keys(errors).length

  return {
    handleSubmit,
    errors,
    isDisabledButton,
    register,
  }
}
