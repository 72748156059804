import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'

import { Container, Spinner } from '@/components'

import { useGetStore } from './hooks'
import {
  StoreDetailsTabContent,
  StoreHistoryTabContent,
  StoreInfraTabContent,
  StoreRawTabContent,
} from './components'
import { useGroup } from '../showGroup/hooks'
import { useAccount } from '../showAccount/hooks'

const tabListHeaders = ['Details', 'Infra', 'History', 'Raw']

export const ShowStore = () => {
  const { store, isLoading: isLoadingStore, storeHistory } = useGetStore()
  const { group, isLoading: isLoadingGroup } = useGroup(store?.groupId)
  const { account, isLoading: isLoadingAccount } = useAccount(store?.groupId, store?.accountId)
  const isLoading = isLoadingGroup || isLoadingStore || isLoadingAccount

  return (
    <div className="flex flex-col grow overflow-hidden">
      {isLoading && <Spinner />}
      <Container
        header={
          <div className="flex grow overflow-hidden xs:flex-col-reverse md:flex-row md:justify-between gap-[15px]">
            <p className="text-2xl font-bold">{store?.name}</p>
          </div>
        }
      >
        <TabGroup className="grow flex flex-col gap-6 max-w-full">
          <TabList className="flex gap-2 items-baseline border-b border-gray-100 px-8">
            {tabListHeaders.map((header, index) => (
              <Tab
                key={index}
                className="-mb-[1px] focus-visible:outline-none py-2 px-4 text-gray-500 text-sm rounded-[4px] rounded-b-none border border-gray-100 bg-[#F5F7FA] data-[selected]:bg-white data-[selected]:border-b-white"
              >
                {header}
              </Tab>
            ))}
          </TabList>
          <TabPanels className="grow overflow-auto">
            <TabPanel>
              <StoreDetailsTabContent
                store={store}
                accountName={account?.company}
                groupName={group?.name}
              />
            </TabPanel>
            <TabPanel>
              <StoreInfraTabContent store={store} />
            </TabPanel>
            <TabPanel>
              <StoreHistoryTabContent history={storeHistory} />
            </TabPanel>
            <TabPanel>
              <StoreRawTabContent store={store} />
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </Container>
    </div>
  )
}
