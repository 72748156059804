import { useMutation } from '@tanstack/react-query'

import { GroupService } from '@/services/group'
import { useNotify } from '@/hooks'
import { BulkEditRequest } from '@/models/group'

import { BulkEditFormData } from '../data/types'

const useBulkUpdateGroup = ({ onSuccess }: { onSuccess?(): void }) => {
  const { notifyError, notifySuccess } = useNotify()

  const bulkUpdate = async (data: BulkEditFormData) => {
    const requestBody: BulkEditRequest = {
      ...data,
      pubsubs: {
        app: {
          ...data.pubsubs.app,
          filterPolicyJson: {
            ...data.pubsubs.app.filterPolicyJson,
            event_type:
              data.pubsubs.app.filterPolicyJson.event_type &&
              JSON.parse(data.pubsubs.app.filterPolicyJson.event_type),
          },
        },
        order: {
          ...data.pubsubs.order,
          filterPolicyJson: {
            ...data.pubsubs.order.filterPolicyJson,
            event_type:
              data.pubsubs.order.filterPolicyJson.event_type &&
              JSON.parse(data.pubsubs.order.filterPolicyJson.event_type),
          },
        },
        notification: {
          ...data.pubsubs.notification,
          filterPolicyJson: {
            ...data.pubsubs.notification.filterPolicyJson,
            event_type:
              data.pubsubs.notification.filterPolicyJson.event_type &&
              JSON.parse(data.pubsubs.notification.filterPolicyJson.event_type),
          },
        },
      },
      ...(data?.cognitoConfig ? { cognitoConfig: JSON.parse(data?.cognitoConfig) } : {}),
    }
    const result = await GroupService.updateGroupBulk(requestBody)
    return result.data
  }

  const { mutate: update, isPending: isUpdating } = useMutation({
    mutationFn: bulkUpdate,
    onSuccess: () => {
      notifySuccess('Bulk update executed successfully')
      onSuccess?.()
    },
    onError: (error) => {
      notifyError(error.message ?? 'There was an error')
    },
  })

  return { update, isUpdating }
}

export { useBulkUpdateGroup }
