import { ComponentProps, ReactNode } from 'react'

import { classNames } from '@/utils'

interface InfoElementProps extends ComponentProps<'div'> {
  label: string
  value: ReactNode
}

export const InfoElement = ({ value, label, className, ...props }: InfoElementProps) => (
  <div className={classNames('flex flex-col gap-2', className)} {...props}>
    <label className="font-medium uppercase text-gray-400 text-sm">{label}</label>
    <div className="text-gray-800 leading-6">{value}</div>
  </div>
)
