import { FC } from 'react'

import { Store } from '@/models/store'

interface Props {
  store?: Store
}

const StoreRawTabContent: FC<Props> = ({ store }) => {
  const outputs = JSON.parse(store?.outputs ?? '{}')

  return (
    <pre className="rounded-lg border border-gray-100 p-6 text-gray-500 text-sm">
      {JSON.stringify({ ...store, outputs }, null, 2)}
    </pre>
  )
}

export { StoreRawTabContent }
