import React, { useEffect, useState } from 'react'
import { OptionType, OptionTypeEmpty } from '../../../../models/optionSelect.ts'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { useDetectClickOutside } from 'react-detect-click-outside'

export interface InputSelectProps extends Omit<React.HTMLProps<HTMLInputElement>, 'ref' | 'as'> {
  listOptions: OptionType[]
  valueOption: OptionType
  handleChange?: (option: OptionType) => void
  isError?: boolean
}

export const InputSelect = ({
  listOptions,
  handleChange,
  valueOption,
  isError,
}: InputSelectProps) => {
  const [isShowOptions, setIsShowOptions] = useState<boolean>(false)
  const [selectedOption, setSelectedOption] = useState<OptionType>(OptionTypeEmpty)
  const ref: any = useDetectClickOutside({ onTriggered: () => setIsShowOptions(false) })

  useEffect(() => {
    if (valueOption?.id) {
      setSelectedOption(valueOption)
    } else {
      setSelectedOption(listOptions[0])
    }
  }, [listOptions, valueOption])

  const handleSelectedOption = (option: OptionType) => {
    setSelectedOption(option)
    setIsShowOptions(false)
    handleChange && handleChange(option)
  }

  return (
    <div className="w-full" ref={ref}>
      <div className="relative">
        <div
          className={`flex justify-between items-center ${isError ? 'border-red border-[1px]' : 'border-[1px] text-gray-400 border-gray-200 focus:border-gray-300 outline-none'} rounded-[6px] text-[14px] h-[40px] px-[20px] cursor-pointer`}
          onClick={() => setIsShowOptions(!isShowOptions)}
        >
          <span>{selectedOption?.label}</span>
          <ChevronDownIcon className="h-[20px] w-[20px] text-black" />
        </div>
        {isShowOptions && (
          <div className="flex flex-col rounded-md bg-white py-1 text-base shadow-lg absolute w-full max-h-[180px] overflow-auto z-[1999]">
            {listOptions.map((element) => (
              <div
                className={`px-[19px] h-auto py-[5px] cursor-pointer hover:bg-green-100 ${selectedOption?.id === element?.id ? 'bg-green-50 text-green-800 font-semibold' : 'bg-white text-black'}`}
                key={element?.id}
                onClick={() => {
                  handleSelectedOption(element)
                }}
              >
                {element?.label}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
