import { useCallback } from 'react'
import { useQuery } from '@tanstack/react-query'

import { ArtifactService } from '@/services/artifacts'
import { Artifacts } from '@/models/artifacts'

export const useArtifacts = () => {
  const queryKey = ['artifacts']

  const getArtifacts = useCallback(async () => {
    const response = await ArtifactService.getArtifacts()
    return response.data as Artifacts
  }, [])

  const { data, isLoading, refetch } = useQuery({
    queryKey,
    queryFn: getArtifacts,
  })

  return { artifacts: data, isLoading, refetch }
}
