import { FormApp, FormControl, InputApp, ModalApp, AddressField } from '@/components'

import { CreateAndEditAccountModalProps } from '../data/types'
import { useCreateAndEditAccountModal } from '../hooks'
import { CreateAccountRequest } from '@/models/account'

export const CreateAndEditAccountModal = ({
  isOpenModal,
  handleCloseModal,
  account,
  handleSubmitAccount,
  isSubmitting,
}: CreateAndEditAccountModalProps) => {
  const { register, handleSubmit, errors, reset } = useCreateAndEditAccountModal({
    account,
  })

  return (
    <ModalApp
      isOpenModal={isOpenModal}
      handleCloseModal={handleCloseModal}
      title={`${account ? 'Edit' : 'Create'} Account`}
      maxHeight="xs:max-h-[820px] md:max-h-[800px]"
      maxWidth="xs:max-w-[340px] md:max-w-[1024px]"
    >
      <FormApp
        handleCancel={handleCloseModal}
        handleSubmit={handleSubmit}
        onSubmit={(data: CreateAccountRequest) => {
          handleSubmitAccount(data).then(() => {
            reset()
          })
        }}
        textButton={account ? 'Save' : 'Create'}
        isSubmitting={isSubmitting}
      >
        <div className="flex flex-col gap-6">
          <fieldset className="w-full rounded-lg border border-[#F0F2F7] p-6 flex flex-col gap-6 self-start">
            <label>Information</label>
            <div className="grid grid-cols-3 gap-4">
              <FormControl label="Company" isRequired messageError={errors.company?.message}>
                <InputApp
                  name="company"
                  placeholder="Company name"
                  register={register}
                  isError={!!errors.company?.message}
                />
              </FormControl>
              <FormControl label="First Name" isRequired messageError={errors.first_name?.message}>
                <InputApp
                  name="first_name"
                  placeholder="First name"
                  register={register}
                  isError={!!errors.first_name?.message}
                />
              </FormControl>
              <FormControl label="Last Name" isRequired messageError={errors.last_name?.message}>
                <InputApp
                  name="last_name"
                  placeholder="Last name"
                  register={register}
                  isError={!!errors.last_name?.message}
                />
              </FormControl>
              <FormControl label="Phone Number" isRequired messageError={errors.phone?.message}>
                <InputApp
                  name="phone"
                  placeholder="Phone number"
                  register={register}
                  isError={!!errors.phone?.message}
                />
              </FormControl>
              <FormControl label="Email" isRequired messageError={errors.email?.message}>
                <InputApp
                  name="email"
                  placeholder="Email"
                  register={register}
                  isError={!!errors.email?.message}
                />
              </FormControl>
            </div>
          </fieldset>
          <fieldset className="w-full rounded-lg border border-[#F0F2F7] p-6 flex flex-col gap-6 self-start">
            <label>Location</label>
            <AddressField parentFieldName="address" errors={errors?.address} register={register} />
          </fieldset>
        </div>
      </FormApp>
    </ModalApp>
  )
}
