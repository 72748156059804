import { BulkEditFormData } from '../data/types'

const stringifyPreviewEditData = (previewData?: BulkEditFormData) =>
  previewData &&
  JSON.stringify(
    {
      ...(previewData.lambdaGraphqlSrcKey
        ? { lambdaGraphqlSrcKey: previewData.lambdaGraphqlSrcKey }
        : {}),
      ...(previewData.lambdaWebHookGroupSrcKey
        ? { lambdaWebHookGroupSrcKey: previewData.lambdaWebHookGroupSrcKey }
        : {}),
      ...(previewData?.pubsubs?.app?.filterPolicyJson?.event_type ||
      previewData?.pubsubs?.order?.filterPolicyJson?.event_type ||
      previewData?.pubsubs?.notification?.filterPolicyJson?.event_type ||
      previewData?.pubsubs?.app?.lambdaSrcKey ||
      previewData?.pubsubs?.order?.lambdaSrcKey ||
      previewData?.pubsubs?.notification?.lambdaSrcKey
        ? {
            pubsubs: {
              ...(previewData?.pubsubs?.app?.filterPolicyJson?.event_type ||
              previewData?.pubsubs?.app?.lambdaSrcKey
                ? {
                    app: {
                      ...(previewData.pubsubs.app.lambdaSrcKey
                        ? { lambdaSrcKey: previewData.pubsubs.app.lambdaSrcKey }
                        : {}),
                      ...(previewData?.pubsubs?.app?.filterPolicyJson?.event_type
                        ? {
                            filterPolicyJson: {
                              event_type: JSON.parse(
                                previewData.pubsubs.app.filterPolicyJson.event_type ?? '',
                              ),
                            },
                          }
                        : {}),
                    },
                  }
                : {}),
              ...(previewData?.pubsubs?.order?.filterPolicyJson?.event_type ||
              previewData?.pubsubs?.order?.lambdaSrcKey
                ? {
                    order: {
                      ...(previewData.pubsubs.order.lambdaSrcKey
                        ? { lambdaSrcKey: previewData.pubsubs.order.lambdaSrcKey }
                        : {}),
                      ...(previewData?.pubsubs?.order?.filterPolicyJson?.event_type
                        ? {
                            filterPolicyJson: {
                              event_type: JSON.parse(
                                previewData.pubsubs.order.filterPolicyJson.event_type ?? '',
                              ),
                            },
                          }
                        : {}),
                    },
                  }
                : {}),
              ...(previewData?.pubsubs?.notification?.filterPolicyJson?.event_type ||
              previewData?.pubsubs?.notification?.lambdaSrcKey
                ? {
                    notification: {
                      ...(previewData.pubsubs.notification.lambdaSrcKey
                        ? { lambdaSrcKey: previewData.pubsubs.notification.lambdaSrcKey }
                        : {}),
                      ...(previewData?.pubsubs?.notification?.filterPolicyJson?.event_type
                        ? {
                            filterPolicyJson: {
                              event_type: JSON.parse(
                                previewData.pubsubs.notification.filterPolicyJson.event_type ?? '',
                              ),
                            },
                          }
                        : {}),
                    },
                  }
                : {}),
            },
            ...(previewData.cognitoConfig
              ? { cognitoConfig: JSON.parse(previewData.cognitoConfig) }
              : {}),
          }
        : {}),
    },
    null,
    2,
  )

export { stringifyPreviewEditData }
