import { FC } from 'react'

import { Account } from '@/models/account'

interface Props {
  account?: Account
}

const AccountRawTabContent: FC<Props> = ({ account }) => (
  <pre className="rounded-lg border border-gray-100 p-6 text-gray-500 text-sm">
    {JSON.stringify(account, null, 2)}
  </pre>
)

export { AccountRawTabContent }
