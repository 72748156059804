import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { BulkEditFormData } from '../data/types'
import { bulkSchema } from '../data/schema'

export const useBulkEditGroupModal = () => {
  const {
    register,
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<BulkEditFormData>({
    defaultValues: {
      lambdaGraphqlSrcKey: undefined,
      lambdaWebHookGroupSrcKey: undefined,
      pubsubs: {
        app: { lambdaSrcKey: undefined, filterPolicyJson: { event_type: undefined } },
        notification: { lambdaSrcKey: undefined, filterPolicyJson: { event_type: undefined } },
        order: { lambdaSrcKey: undefined, filterPolicyJson: { event_type: undefined } },
      },
      cognitoConfig: undefined,
    },
    mode: 'onChange',
    resolver: yupResolver(bulkSchema),
  })

  const isDisabledButton =
    !watch().lambdaGraphqlSrcKey &&
    !watch().lambdaWebHookGroupSrcKey &&
    !watch().cognitoConfig &&
    !watch().pubsubs.app.lambdaSrcKey &&
    !watch().pubsubs.order.lambdaSrcKey &&
    !watch().pubsubs.notification.lambdaSrcKey &&
    !watch().pubsubs.app.filterPolicyJson.event_type &&
    !watch().pubsubs.order.filterPolicyJson.event_type &&
    !watch().pubsubs.notification.filterPolicyJson.event_type

  return {
    handleSubmit,
    errors,
    register,
    isDisabledButton,
    control,
  }
}
