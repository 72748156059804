import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useNotify } from '@/hooks'
import { CreateStoreRequest } from '@/models/store'
import { StoreService } from '@/services/store'

const useCreateStore = ({ onSuccess }: { onSuccess?(): void }) => {
  const { notifyError, notifySuccess } = useNotify()
  const queryClient = useQueryClient()

  const create = async (data: CreateStoreRequest) => {
    const result = await StoreService.createStore(data)
    return result.data
  }

  const { mutate: createStore, isPending: isCreating } = useMutation({
    mutationFn: create,
    onSuccess: async (_, { accountId }) => {
      await queryClient.refetchQueries({ queryKey: ['stores', accountId], type: 'all' })
      await queryClient.invalidateQueries({ queryKey: ['all-groups'], type: 'all' })
      notifySuccess('Store created successfully')
      onSuccess?.()
    },
    onError: (error) => {
      notifyError(error.message ?? 'There was an error')
    },
  })

  return { createStore, isCreating }
}

export { useCreateStore }
