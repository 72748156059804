import {
  createContext,
  DetailsHTMLAttributes,
  FC,
  HTMLAttributes,
  PropsWithChildren,
  useContext,
  useState,
  Dispatch,
  useEffect,
  ComponentProps,
  ReactNode,
} from 'react'
import { ChevronDownIcon } from '@heroicons/react/24/solid'

import { classNames } from '@/utils'

interface CollapseState {
  isOpen: boolean
  setIsOpen: Dispatch<React.SetStateAction<boolean>>
}

const CollapseContext = createContext<CollapseState | undefined>(undefined)

const useCollapseContext = () => {
  const context = useContext(CollapseContext)

  if (!context) throw new Error('useCollapseContext should be used within Collapse Provider')

  return context
}

interface CollapseProps extends Omit<ComponentProps<'div'>, 'children'> {
  defaultExpanded?: boolean
  expanded?: boolean
  children: ReactNode | ((_: { open: boolean }) => ReactNode)
}

const Collapse: FC<CollapseProps> = ({
  children,
  className,
  defaultExpanded,
  expanded,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(!!defaultExpanded)

  useEffect(() => {
    if (expanded !== undefined) {
      setIsOpen(!!expanded)
    }
  }, [expanded])

  return (
    <CollapseContext.Provider value={{ isOpen, setIsOpen }}>
      <div className={className} {...props}>
        {typeof children === 'function' ? children({ open: isOpen }) : children}
      </div>
    </CollapseContext.Provider>
  )
}

const CollapseHeader: FC<PropsWithChildren<HTMLAttributes<HTMLElement>>> = ({
  children,
  className,
  ...props
}) => {
  const { isOpen, setIsOpen } = useCollapseContext()

  const handleClick = () => {
    setIsOpen((open) => !open)
  }

  return (
    <div
      className={classNames(
        'w-full flex gap-2 justify-between items-start list-none mb-4 cursor-pointer',
        className,
      )}
      role="button"
      onClick={handleClick}
      {...props}
    >
      {children}
      <ChevronDownIcon
        className={classNames('rotate-0 transition-all duration-300 ease-in-out h-4 font-bold', {
          'rotate-180': isOpen,
        })}
      />
    </div>
  )
}

const CollapsePanel: FC<PropsWithChildren<HTMLAttributes<HTMLDivElement>>> = ({
  children,
  className,
  ...props
}) => {
  const { isOpen } = useCollapseContext()

  return (
    <div
      className={classNames(
        'grid grid-rows-[0fr] transition-[grid-template-rows] duration-300 ease-in-out',
        {
          'grid-rows-[1fr]': isOpen,
        },
      )}
    >
      <div
        className={classNames('overflow-hidden transition-all duration-300 ease-in-out', className)}
        {...props}
      >
        {children}
      </div>
    </div>
  )
}

const EnhancedCollapse = Object.assign(Collapse, { Header: CollapseHeader, Panel: CollapsePanel })

export { EnhancedCollapse as Collapse }
