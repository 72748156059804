import { useState } from 'react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { ArrowLeftEndOnRectangleIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import { useDetectClickOutside } from 'react-detect-click-outside'

import { ROUTE_KEY } from '@/constants/localStorageKeys'

export const NavBar = () => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false)
  const { user, signOut } = useAuthenticator((context) => [context.user])
  const ref = useDetectClickOutside({ onTriggered: () => setIsCollapsed(false) })

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  const handleLogout = () => {
    signOut()
    localStorage.removeItem(ROUTE_KEY)
  }

  return (
    <div className="h-[72px] text-primary bg-white shadow-md w-full flex justify-center z-[2000] flex-shrink-0">
      <div className={`px-[15px] justify-end flex items-center h-full w-full`}>
        <div className={`flex flex-row justify-end items-center space-x-5 pr-0`} ref={ref}>
          <div className="relative">
            <div
              className="flex items-center gap-[12px] cursor-pointer rounded-[8px] p-[20px]"
              onClick={handleToggleCollapse}
            >
              <UserCircleIcon className="h-[30px] w-[30px] text-black" />
            </div>
            {isCollapsed && (
              <div className="absolute min-w-64 w-auto right-0 top-[80px] bg-white shadow-lg px-[20px] py-[30px] rounded ">
                <div className="text-sm font-normal italic text-black mb-8">{user?.username}</div>
                <div
                  className="cursor-pointer flex gap-2 items-center border border-light-gray rounded-full p-2"
                  onClick={handleLogout}
                >
                  <ArrowLeftEndOnRectangleIcon className="text-black h-[20px] w-[40px]" />
                  <span className="text-black">Logout</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
