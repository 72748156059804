import { FC } from 'react'

import { Group } from '@/models/group'
import { ClipboardItem } from '@/components'

interface Props {
  group?: Group
}

const GroupInfraTabContent: FC<Props> = ({ group }) => {
  const parsedOutput = group?.outputs && JSON.parse(group?.outputs)
  const graphQLUrl = parsedOutput?.ApiGateways?.ApiGatewayGraphqlGroup?.url
  const webhookUrl = parsedOutput?.ApiGateways?.apiGatewayWebHookGroup?.url
  const arn = parsedOutput?.CognitoUserPoolGroup?.arn
  const userPoolId = parsedOutput?.CognitoUserPoolGroup?.id
  const webClientId = parsedOutput?.CognitoUserPoolGroup?.client?.id
  const sns = parsedOutput?.SNS
  const sqs = parsedOutput?.SQS
  const buckets = parsedOutput?.Buckets
  const lambdas = parsedOutput?.Lambdas

  const sections = [
    {
      title: 'API',
      items: [
        { label: 'GraphQL URL', value: graphQLUrl },
        { label: 'Webhook URL', value: webhookUrl },
      ],
    },
    {
      title: 'IDP',
      items: [
        { label: 'ARN', value: arn },
        { label: 'User Pool ID', value: userPoolId },
        { label: 'Web Client ID', value: webClientId },
      ],
    },
    {
      title: 'SNS',
      items: Object.entries(sns ?? {}).map(([key, value]) => ({ label: key, value })),
    },
    {
      title: 'SQS',
      items: Object.entries(sqs ?? {}).map(([key, value]) => ({ label: key, value })),
    },
    {
      title: 'Buckets',
      items: [
        {
          label: 'GroupName',
          value: buckets?.ConfigsStoresGroup?.name,
        },
      ],
    },
    {
      title: 'Lambdas',
      items: [
        { label: 'GraphQL', value: lambdas?.Graphql?.name },
        { label: 'Webhook', value: lambdas?.WebHook?.name },
      ],
    },
  ]

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-wrap divide-x-2 divide-gray-100">
        {sections.slice(0, 2).map(({ title, items }) => (
          <div className="flex-1 w-1/2 flex flex-col gap-4 even:pl-6 odd:pr-6">
            <label className="py-2">{title}</label>
            {items.map(({ label, value }, index) => (
              <ClipboardItem key={index} headless value={value}>
                {({ onClick, clipboardIcon }) => (
                  <div className="grid grid-cols-[132px_1fr_auto] gap-6">
                    <div className="text-gray-400 break-all">{label}:</div>
                    <span className="text-gray-700 break-all">{value}</span>
                    <div className="flex justify-end" onClick={onClick} role="button">
                      <span className="self-start">{clipboardIcon}</span>
                    </div>
                  </div>
                )}
              </ClipboardItem>
            ))}
          </div>
        ))}
      </div>
      <div className="font-bold text-gray-700 pt-12">PubSub</div>
      <div className="flex flex-wrap">
        {sections.slice(2).map(({ title, items }) => (
          <div className="w-1/2 flex flex-col gap-4 even:pl-6 odd:pr-6 odd:border-r-2 odd:border-gray-100 mb-12">
            <label className="py-2">{title}</label>
            {items.map(({ label, value }, index) => (
              <ClipboardItem key={index} headless value={value}>
                {({ onClick, clipboardIcon }) => (
                  <div className="grid grid-cols-[132px_1fr_auto] gap-6">
                    <div className="text-gray-400 break-all">{label}:</div>
                    <span className="text-gray-700 break-all">{value}</span>
                    <div className="flex justify-end" onClick={onClick} role="button">
                      <span className="self-start">{clipboardIcon}</span>
                    </div>
                  </div>
                )}
              </ClipboardItem>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

export { GroupInfraTabContent }
