import { StoreInterface } from '@/services/interfaces/store'
import { sendRequest } from '@/services/dataSources/axiosConfig'
import { RequestProps } from '@/services/dataSources/types'
import { AsyncApiResponse } from '@/models/apiResponse'
import { CreateStoreRequest, Store, StoreBulkEditRequest, UpdateStoreRequest } from '@/models/store'
import { Record } from '@/models/record.ts'

class StoreServiceImplementation implements StoreInterface {
  async getAllStores(): AsyncApiResponse<Store[]> {
    const request: RequestProps = {
      get: {
        path: '/store',
      },
    }
    return await sendRequest<Store[]>(request)
  }

  async getStoreDetail(id: string): AsyncApiResponse<Store> {
    const request: RequestProps = {
      get: {
        path: `/store/${id}`,
      },
    }
    return await sendRequest<Store>(request)
  }

  async getStoreHistory(id: string): AsyncApiResponse<Record[]> {
    const request: RequestProps = {
      get: {
        path: `/store/${id}/history`,
      },
    }
    return await sendRequest<Record[]>(request)
  }

  async createStore(data: CreateStoreRequest): AsyncApiResponse<boolean> {
    const request: RequestProps = {
      post: {
        path: `/store`,
        body: data,
      },
    }
    return await sendRequest<boolean>(request)
  }

  async updateStore(data: UpdateStoreRequest): AsyncApiResponse<boolean> {
    const request: RequestProps = {
      put: {
        path: `/store/${data.id}`,
        body: data,
      },
    }
    return await sendRequest<boolean>(request)
  }

  async getLastStores(limit: number): AsyncApiResponse<Store[]> {
    const request: RequestProps = {
      get: {
        path: `/store/last/${limit}`,
      },
    }
    return await sendRequest<Store[]>(request)
  }

  async updateStoreBulk(data: StoreBulkEditRequest): AsyncApiResponse<boolean> {
    const request: RequestProps = {
      post: {
        path: `/store/bulk/stores`,
        body: data,
      },
    }
    return await sendRequest<boolean>(request)
  }
}

export const StoreService = new StoreServiceImplementation()
