import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { AgGridApp } from '@/components'
import { Account } from '@/models/account'
import { ROUTES_PATHS } from '@/routes'
import { Group } from '@/models/group'

interface Props {
  group?: Group
  groupAccounts: Account[]
  columnsAccountsDefs: {
    field?: string
    headerName: string
    cellRenderer?: (props: { data: Account }) => JSX.Element
  }[]
}

const GroupAccountsTabContent: FC<Props> = ({ groupAccounts, columnsAccountsDefs, group }) => {
  const navigate = useNavigate()

  const goToAccountDetails = (account: Account) => {
    navigate(`${ROUTES_PATHS.GROUPS}/${group?.id}/${account.id}`)
  }

  return (
    <div className="flex flex-col gap-6">
      <div className="text-gray-800 font-medium">Accounts</div>
      <AgGridApp
        rowData={groupAccounts}
        columnDefs={columnsAccountsDefs}
        isShowPagination={false}
        isShowFilter={false}
        paginationPageSize={20}
        onRowDoubleClicked={(e) => {
          goToAccountDetails(e.data)
        }}
      />
    </div>
  )
}

export { GroupAccountsTabContent }
