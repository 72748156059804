import { useState } from 'react'
import { CgSpinner } from 'react-icons/cg'
import { RiArrowGoBackLine } from 'react-icons/ri'

import {
  ButtonApp,
  Collapse,
  FormApp,
  FormControl,
  ModalApp,
  SelectField,
  TextAreaInput,
} from '@/components'
import { classNames, getVersion } from '@/utils'

import { BulkEditFormData, BulkEditModalProps } from '../data/types'
import { useBulkEditStoreModal, useBulkUpdateStore } from '../hooks'
import { stringifyPreviewEditData } from '../utils'
import { useArtifacts } from '../../groups/hooks'

export const BulkEditModal = ({ isOpenModal, handleCloseModal }: BulkEditModalProps) => {
  const { register, handleSubmit, errors, isDisabledButton, control } = useBulkEditStoreModal()
  const { artifacts, isLoading } = useArtifacts()
  const { update, isUpdating } = useBulkUpdateStore({ onSuccess: handleCloseModal })
  const [previewData, setPreviewData] = useState<BulkEditFormData | undefined>(undefined)
  const previewDataString = stringifyPreviewEditData(previewData)

  const lambdaAlgoliaSrcKeyOptions =
    artifacts?.['e-com-dynamodbstream-srv']?.map((artifact) => ({
      label: getVersion(artifact) ?? artifact,
      value: `e-com-dynamodbstream-srv/${artifact}`,
    })) ?? []
  const cognitoLambdaTriggerSrcKeyOptions =
    artifacts?.['e-com-cognito-worker']?.map((artifact) => ({
      label: getVersion(artifact) ?? artifact,
      value: `e-com-cognito-worker/${artifact}`,
    })) ?? []

  const renderItemTemplate = (value: string) => (
    <span className="text-black rounded-full px-2 text-xs w-fit h-6 flex items-center bg-[#f5a66c]">
      {getVersion(value)}
    </span>
  )

  return (
    <ModalApp
      isOpenModal={isOpenModal}
      handleCloseModal={handleCloseModal}
      title="Bulk Edit"
      maxHeight="xs:max-h-[820px] md:max-h-[800px]"
      maxWidth={classNames('xs:max-w-[340px] md:max-w-[600px]', {
        'md:max-w-[600px]': !!previewData,
      })}
    >
      {!!previewData && (
        <div className="flex flex-col">
          <div className="p-8 max-h-[60vh] overflow-auto">
            <pre className="border border-gray-200 rounded-[4px] p-4 text-gray-400 overflow-auto">
              {previewDataString}
            </pre>
          </div>
          <div className="flex xs:justify-center md:justify-end w-full py-6 px-8 border-t border-[#F0F2F7]">
            <div className="flex xs:flex-col-reverse md:flex-row md:justify-end gap-4 w-full">
              <ButtonApp
                handleClick={() => setPreviewData(undefined)}
                isPrimary={false}
                width="xs:w-full md:w-[160px]"
                className="h-12 flex gap-2 items-center"
              >
                <RiArrowGoBackLine className="text-lg" />
                <span>Go Back</span>
              </ButtonApp>
              <ButtonApp
                isPrimary
                width="xs:w-full md:w-[160px]"
                className="h-12 flex gap-2 items-center"
                handleClick={() => update(previewData)}
              >
                {isUpdating && <CgSpinner className="animate-spin text-lg" />}
                <span>Apply</span>
              </ButtonApp>
            </div>
          </div>
        </div>
      )}
      <FormApp
        handleCancel={handleCloseModal}
        handleSubmit={handleSubmit}
        onSubmit={(data: BulkEditFormData) => {
          setPreviewData(data)
        }}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onError={(errors: any) => {
          console.log(errors)
        }}
        textButton="Preview changes"
        isDisabledButton={isDisabledButton}
        className={classNames({ hidden: !!previewData })}
      >
        <div className="flex flex-col gap-6">
          <fieldset className="w-full flex flex-col gap-6 self-start">
            <label>API</label>
            <div className="grid grid-cols-2 gap-6">
              <FormControl label="Algolia">
                <SelectField
                  name="lambdaAlgoliaSrcKey"
                  register={register}
                  options={lambdaAlgoliaSrcKeyOptions}
                  placeholder="Select version"
                  isLoading={isLoading}
                  control={control}
                  valueTemplate={renderItemTemplate}
                  itemTemplate={renderItemTemplate}
                  optionClassName="data-[focus]:bg-yellow-50 data-[selected]:bg-yellow-50 hover:bg-yellow-50"
                  checkIconClassName="text-yellow-100 group-data-[selected]:text-[#f5a66c]"
                />
              </FormControl>
              <FormControl label="Cognito">
                <SelectField
                  name="cognitoLambdaTriggerSrcKey"
                  register={register}
                  options={cognitoLambdaTriggerSrcKeyOptions}
                  placeholder="Select version"
                  isLoading={isLoading}
                  control={control}
                  valueTemplate={renderItemTemplate}
                  itemTemplate={renderItemTemplate}
                  optionClassName="data-[focus]:bg-yellow-50 data-[selected]:bg-yellow-50 hover:bg-yellow-50"
                  checkIconClassName="text-yellow-100 group-data-[selected]:text-[#f5a66c]"
                />
              </FormControl>
            </div>
          </fieldset>
          <Collapse className="w-full col-span-2">
            {({ open }) => (
              <>
                <Collapse.Header className="justify-end items-center text-gray-400 text-sm">
                  <div className="flex gap-1 cursor-pointer items-start">{`${open ? 'Hide' : 'Show'} details`}</div>
                </Collapse.Header>
                <Collapse.Panel>
                  <fieldset className="flex flex-col gap-6 self-start">
                    <label>IDP</label>
                    <FormControl messageError={errors.cognitoConfig?.message}>
                      <TextAreaInput
                        rows={7}
                        name="cognitoConfig"
                        placeholder="Enter cognito config"
                        register={register}
                        isError={!!errors.cognitoConfig?.message}
                      />
                    </FormControl>
                  </fieldset>
                </Collapse.Panel>
              </>
            )}
          </Collapse>
        </div>
      </FormApp>
    </ModalApp>
  )
}
