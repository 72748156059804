import { FC } from 'react'

import { ClipboardItem } from '@/components'
import { Store } from '@/models/store'

interface Props {
  store?: Store
}

const StoreInfraTabContent: FC<Props> = ({ store }) => {
  const parsedOutput = store?.outputs && JSON.parse(store?.outputs)
  const arn = parsedOutput?.CognitoUserPoolGroup?.arn
  const userPoolId = parsedOutput?.CognitoUserPoolGroup?.id
  const webClientId = parsedOutput?.CognitoUserPoolGroup?.client?.id
  const dynamoDB = parsedOutput?.DynamoDbTableStore
  const algoliaIndexName = parsedOutput?.Algolia?.indexName

  const sections = [
    {
      title: 'IDP',
      items: [
        { label: 'ARN', value: arn },
        { label: 'User Pool ID', value: userPoolId },
        { label: 'Web Client ID', value: webClientId },
      ],
    },
    {
      title: 'DynamoDB',
      items: Object.entries(dynamoDB ?? {}).map(([key, value]) => ({ label: key, value })),
    },
    ...(algoliaIndexName
      ? [
          {
            title: 'Algolia Index',
            items: [{ label: 'Index Name', value: algoliaIndexName }],
          },
        ]
      : []),
  ]

  return (
    <div className="flex flex-col gap-10">
      <div className="flex divide-x-2 divide-gray-100">
        {sections.slice(0, 2).map(({ title, items }, index) => (
          <div key={index} className="flex-1 w-1/2 flex flex-col gap-4 even:pl-6 odd:pr-6">
            <label className="py-2">{title}</label>
            {items.map(({ label, value }, index) => (
              <ClipboardItem key={index} headless value={value}>
                {({ onClick, clipboardIcon }) => (
                  <div className="grid grid-cols-[132px_1fr_auto] gap-6">
                    <div className="text-gray-400 break-all">{label}:</div>
                    <span className="text-gray-700 break-all">{value}</span>
                    <div className="flex justify-end" onClick={onClick} role="button">
                      <span className="self-start">{clipboardIcon}</span>
                    </div>
                  </div>
                )}
              </ClipboardItem>
            ))}
          </div>
        ))}
      </div>
      <div className="flex divide-x-2 divide-gray-100">
        {sections.slice(2).map(({ title, items }, index) => (
          <div
            key={index}
            className="flex-1 w-1/2 max-w-[50%] flex flex-col gap-4 even:pl-6 odd:pr-6"
          >
            <label className="py-2">{title}</label>
            {items.map(({ label, value }, index) => (
              <ClipboardItem key={index} headless value={value}>
                {({ onClick, clipboardIcon }) => (
                  <div className="grid grid-cols-[132px_1fr_auto] gap-6">
                    <div className="text-gray-400 break-all">{label}:</div>
                    <span className="text-gray-700 break-all">{value}</span>
                    <div className="flex justify-end" onClick={onClick} role="button">
                      <span className="self-start">{clipboardIcon}</span>
                    </div>
                  </div>
                )}
              </ClipboardItem>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

export { StoreInfraTabContent }
