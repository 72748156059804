import * as yup from 'yup'

import { addressSchema } from '@/schemas'

export const accountSchema = yup.object({
  company: yup.string().required('Company is required'),
  first_name: yup.string().required('Domain is required'),
  last_name: yup.string().required('Last name is required'),
  email: yup.string().required('Email is required'),
  phone: yup.string().required('Phone is required'),
  address: addressSchema.required('Address is required'),
})

const cognitoCustomAttributeSchema = yup.object({
  name: yup.string().required('Custom attribute name is required'),
  mutable: yup.boolean().required('Mutable is required'),
  minLen: yup.number().optional(),
  maxLen: yup.number().optional(),
})

const cognitoRoleSchema = yup.object({
  roleName: yup.string().required('Role name is required'),
  description: yup.string().optional(),
})

const cognitoConfigSchema = yup.object({
  customAttributes: yup.array().of(cognitoCustomAttributeSchema).optional().default([]),
  defaultAttributes: yup
    .array()
    .of(yup.string().required('Default attribute must be a string'))
    .optional()
    .default([]),
  roles: yup.array().of(cognitoRoleSchema).optional().default([]),
})

export const backendResourcesSchema = yup.object({
  id: yup.string().required('Group ID is required'),
  created: yup.string().required('Creation date is required'),
  updated: yup.string().required('Update date is required'),
  workflowStatus: yup.string().required('Workflow status is required'),
  workflowId: yup.string().required('Workflow ID is required'),
  stage: yup.string().optional(),
  accountId: yup.string().required('Account ID is required'),
  groupId: yup.string().required('Group ID is required'),
  name: yup.string().required('Name is required'),
  description: yup.string().required('Description is required'),
  lambdaAlgoliaSrcKey: yup.string().required('Lambda Algolia source key is required'),
  cognitoLambdaTriggerSrcKey: yup
    .string()
    .required('Cognito Lambda trigger source key is required'),
  artifactsBucketName: yup.string().optional(),
  apiLambdaSrcKey: yup.string().optional(),
  apiLambdaVersion: yup.string().optional(),
  outputs: yup.string().optional(),
  cognitoConfig: yup
    .string()
    .test('is-valid-cognito-config', 'Must be a valid cognito configuration', (value) => {
      if (!value) return true
      try {
        const parsedValue = JSON.parse(value)
        return cognitoConfigSchema.isValidSync(parsedValue)
      } catch (error) {
        return false
      }
    }),
})
