import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { StoreService } from '@/services/store'
import { Param } from '@/models/params'
import { ApiRequestException, Status } from '@/models/apiResponse'
import { Record } from '@/models/record'
import { useNotify } from '@/hooks'

import { useStore } from './useStore'

export const useGetStore = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [storeHistory, setStoreHistory] = useState<Record[]>([])
  const { storeId } = useParams<Param>()
  const { notifyError } = useNotify()
  const { store, isLoading: isLoadingStore } = useStore(storeId)

  const handleGetStoreHistory = useCallback(async () => {
    try {
      const response = await StoreService.getStoreHistory(storeId as string)
      if (response.status === Status.success) {
        const data = response.data as Record[]
        setStoreHistory(data)
      }
    } catch (error: unknown) {
      const errorResponse = error as ApiRequestException
      notifyError(errorResponse?.error?.content ?? 'There is an error')
    }
  }, [storeId])

  useEffect(() => {
    setIsLoading(true)
    Promise.all([handleGetStoreHistory()]).finally(() => setIsLoading(false))
  }, [])

  return {
    isLoading: isLoading || isLoadingStore,
    store,
    storeHistory,
  }
}
